import { Box, Button, Drawer, Grid, Menu, MenuItem, OutlinedInput, Tab, Tabs, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { AppLogo } from "../icons/AppIcon";
import { AccountCircleOutlined, KeyboardArrowDown, SearchOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Home from "../HomePage/HomePage";
import { logOutApi } from "../apiService/apiService";

const useStyles = makeStyles((theme) => ({
  globalSearchinput: {
    height: "35px",
    width: "100%",
    backgroundColor: "#E5F1FB",
    borderRadius: "20px",
    border: "none"
  },
  oneTab: {
    minWidth: "fit-content",
    color: "#000000",
    fontWeight: "bold",
    height: "40px",
    fontFamily: "Futura PT Book",
    fontSize: '12px !important',
    padding: '6px 9px !important'
  },
  tabs: {
    margin: "10px 0px 10px 0px",
  },
  subMenu: {
    color: "#000000",
    fontFamily: "Futura PT Book",
    display: "flex",
    justifyItems: "center",
  },
  subMenuItem: {
    fontFamily: 'Futura PT Book',
    fontWeight: 'bold',
    fontSize: "13px",
  },
  menuHead: {
    fontFamily: 'Futura PT Book',
    /* color: #4c4c4c; */
    fontSize: '12px !important',
    fontWeight: "bold",
    paddingRight: "0px",
  }
}));

const menuList = [
  // {label : "Profile",path : "/profile"},
  { label: "Log out", path: "/" },
]

const AppHeader = (props) => {
  const matches = useMediaQuery('(min-width:900px)');
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [index, setIndex] = useState(null);
  const [showSubmenuList, setShowSubmenuList] = useState([]);
  const [anchorEquityEl, setAnchorEquityEl,] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { NavabarList, page, setPage, submenu, setSubmenu, submenuEtfs, setSubmenuEtfs, setSubmenuIndecies, setSubmenuHm } = props;
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (e) => {
    console.log("Global Search");
  };

  const history = useHistory();

  const handleEquitiessClose = () => {
    setAnchorEquityEl(null);
  }
  const handleEquitiesClick = (e) => {
    setAnchorEquityEl(e.currentTarget);
  }

  const onTabClick = (tabName, setState) => {
    setPage(tabName.id)
    setShowSidebar(false);
    setShowSubmenuList([])
    setShowSubmenu(false)
  }
  const handleMenuClick = () => {
    setShowSidebar(true);
  }
  const logout = () => {
    localStorage.clear() // Clear token
    history.push(menuList[0].path); // Redirect to login page
  };
  useEffect(() => {
    let timeoutId;

    if (localStorage.token) {
      const sixHoursInMilliseconds = 10 * 60 * 60 * 1000;
      timeoutId = setTimeout(logout, sixHoursInMilliseconds);
    }

    return () => {
      clearTimeout(timeoutId); // Clear timeout on component unmount or token change
    };
  }, [localStorage.token]);

  const onClickSubmenu = (el, e, setState) => {
    setAnchorEl(el.currentTarget)
    // setPage(e.id)
    if (e.subMenu) {
      setIndex(e.id)
    }
    setShowSidebar(false);
    setShowSubmenuList(e.subMenu)
    setShowSubmenu(true)
  }

  const onClickItem = (e, value) => {
    // if(!e.subMenu)setPage(e.id)
    setShowSidebar(false);
    setShowSubmenu(false)
    handleClose()
    switch (index) {
      case 1:
        setSubmenuIndecies(value)
        setPage(index)
      case 2:
        setSubmenuEtfs(value)
        setPage(index)
      case 3:
        setSubmenu(value)
        setPage(index)
      case 4:
        setSubmenuHm(value)
        setPage(index)
      default:
        return 0
    }

  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          <Grid item xs={4} sm={4} md={2} className=" my-2 pl-4" style={{ display: 'flex' }}>
            {!matches && <Button className="menu-icon" onClick={handleMenuClick}>
              <MenuIcon />
            </Button>}
            <Grid  onClick={() => window.location.reload()} >
              <AppLogo width="135px"/>
            </Grid>
          </Grid>
          {/* {matches && <Grid container item xs={6} sm={6} md={3}
            justifyContent="flex-start" alignItems="center"
            width={"100%"}
          >
            <OutlinedInput
              className={classes.globalSearchinput}
              startAdornment={<SearchOutlined className="w-5 mr-2" />}
              placeholder="Search for stocks & more"
            />
          </Grid>} */}
          {matches && <Grid item container xs={10} sm={10} md={9} justifyContent="flex-start" alignItems="flex-start">
            <Tabs value={page} className={classes.tabs}>
              {NavabarList?.map((e, i) => {
                if (e.subMenu) {
                  return <div key={e.id} className="flex">
                    <Box onClick={(el) => onClickSubmenu(el, e)} className={classes.subMenu}>
                      {/* <Tab key={e.id} label={`${e.label}`} className={classes.oneTab} style={{ paddingRight: "0px" }} /> */}
                      <Button id="basic-button" aria-controls={open ? 'basic-menu' : undefined}
                        className={classes.menuHead} style={{ color: `${page === e.id ? "#000000" : "#4c4c4c"}` }}
                        aria-haspopup="true" aria-expanded={open ? 'true' : undefined}
                      >
                        {e.label}
                      </Button>
                      <Box style={{ cursor: 'pointer', display: "flex", alignItems: "center" }}>
                        <KeyboardArrowDown style={{ color: `${page === e.id ? "#000000" : "#4c4c4c"}` }} />
                      </Box>
                    </Box>
                    <Menu id="basic-menu" className="mt-12 ml-2 sub-menu"
                      anchorEl={anchorEl} open={open} onClose={handleClose}>
                      {showSubmenuList?.map((subItem) => (
                        <MenuItem key={subItem.value} className={`${classes.subMenuItem} submenu-tabs`} onClick={() => onClickItem(e, subItem.value)}>{subItem.label}</MenuItem>
                      ))}
                    </Menu>
                  </div>
                } else {
                  return (
                    <Tab key={e.id} label={`${e.label}`}
                      className={classes.oneTab}
                      onClick={() => onTabClick(e, setPage)} />
                  )
                }
              })}
            </Tabs>
          </Grid>}
          <Grid item container xs={2} sm={2} md={1} justifyContent="flex-end" alignItems="center">
            <Button>
              <AccountCircleOutlined
                onClick={handleEquitiesClick}
                className="text-black"
              />
            </Button>
            <Menu
              id="equities"
              keepMounted
              anchorEl={anchorEquityEl}
              open={Boolean(anchorEquityEl)}
              onClose={handleEquitiessClose}
              classes={{ list: "w-32 text-center" }}
              style={{ marginTop: "50px" }}
            >
              {menuList?.map((item, index) => (
                <MenuItem key={index}
                  onClick={async () => {
                    if (item.label === "Log out") {
                      const logOutStatus = await logOutApi()
                      if (logOutStatus?.data?.success) {
                        localStorage.clear()
                        history.push(`${item.path}`);
                      }
                    }
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </Grid>

      <Drawer
        variant="temporary"
        open={showSidebar}
      // anchor={'right'}
      >
        <Grid >
          <div className='flex justify-end' style={{ width: '250px', margin: '10px' }}>
            <CloseIcon onClick={() => { setShowSidebar(false) }} />
          </div>
          <Grid className='sideNav'>

            {NavabarList?.map((e, i) => {
              return (
                <Button
                  value={page}
                  className=" text-black font-medium mr-4 nav-link-btn"
                  key={e.id} onClick={() => onTabClick(e, setPage)}>
                  {`${e.label}`}
                </Button>
              )
            })}
          </Grid>

        </Grid>
      </Drawer>
      {/* <Home /> */}
    </Grid>
  );
  {/* <Grid item xs={3}>
      <AppLogo classes="ml-0" />
    </Grid> */}
  {/* <Grid item className="ml-auto flex items-center mr-8" xs="auto"> */ }
  {/* <Typography className="text-black">Welcome User <Person/> Test User </Typography> */
  }
  {/* <Button>
        <Typography><AccountCircleOutlined onClick={handleEquitiesClick} className="text-black" /></Typography>
      </Button> */}

  {/* </Grid> */ }
}


export default AppHeader;