import { Grid, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import LineComparison from './Line';
import SingleCandleStick2 from '../common/SingleCandleStick2';
import { getColorPr, rangePerc } from '../../utils';
import MetricCandleStick from '../common/MetricCandleStick';

function TimeFrameContainer(props) {
    const { timeFrames, tickerData } = props;

    return (
        <>
            <Grid className='info-container'>
                {timeFrames.length && timeFrames.map((e, i) =>

                    <Grid key={i} className='info-box' >
                        <Grid className='flex flex-col justify-between '>
                            <Grid className='flex flex-row w-full'>
                                <Grid className='time-frame mt-2 w-1/3'>
                                    <Typography className='particular-frame'>{e.timeFrame}</Typography>
                                    <Grid className='w-full' style={{ borderRadius: '10px' }}>
                                        <Grid className='percent flex justify-center' style={{ color: e.percentage >= 0 ? "#339249" : "#DA2424", padding: '0px 6px', fontSize: '9px' }}>{e.percentage}</Grid>
                                    </Grid>
                                </Grid>
                                {tickerData?.lastTradedPrice ? <Grid className='standard-observ-tf w-2/3 pl-2 pr-2'>
                                    <MetricCandleStick percentage={e?.observations?.rest_data?.percentage} open={e?.curr_open == 0 ? tickerData?.openPrice : e.curr_open} high={e?.curr_high ? e.curr_high : 0} close={tickerData?.lastTradedPrice} low={e?.curr_low ? e.curr_low : 0} bg='#DFEAF2'
                                        poscount={e?.observations?.poscount} negcount={e?.observations?.negcount} arrow={e?.observations?.arrow} totalMetrics={e?.observations?.totalMetrics} prev_range={e?.observations?.rest_data?.pre_percentage ? e?.observations?.rest_data?.pre_percentage : e.prev_Range_perc} br="10px" />
                                </Grid> : <></>}
                            </Grid>
                            <Grid className='w-full flex'>
                                <Grid className='flex flex-col justify-center w-1/4'>
                                    <Grid className="high-low-box border-l-0">
                                        <Tooltip title='Current open' arrow placement="top">
                                            <Typography noWrap align='center' className="fontCss high">C.O: {e?.curr_open == 0 ? tickerData?.openPrice : e?.curr_open}</Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid className="high-low-box border-l-0 rounded-bl-md" >
                                        <Tooltip title='Previous Close' arrow placement="top">
                                            <Typography noWrap align='center' className="fontCss high">P.C: {e?.prev_close ? e?.prev_close : 0}</Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid className='flex flex-col justify-center w-1/4'>
                                    <Grid className="high-low-box">
                                        <Tooltip title='Current High' arrow placement="top">
                                            <Typography noWrap align='center' className="fontCss high">C.H: {e?.curr_high ? e?.curr_high : 0}</Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid className="high-low-box">
                                        <Tooltip title='Previous High' arrow placement="top">
                                            <Typography noWrap align='center' className="fontCss high">P.H: {e?.prev_high ? e?.prev_high : 0}</Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid className='flex flex-col justify-center w-1/4'>
                                    <Grid className="high-low-box">
                                        <Tooltip title='Current Low' arrow placement="top">
                                            <Typography noWrap align='center' className="fontCss low">C.L: {e?.curr_low ? e?.curr_low : 0}</Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid className="high-low-box">
                                        <Tooltip title='Previous Low' arrow placement="top">
                                            <Typography noWrap align='center' className="fontCss low">P.L: {e?.prev_low ? e?.prev_low : 0}</Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>

                                <Grid className='flex flex-col justify-center w-1/4'>
                                    <Grid className="high-low-box">
                                        <Tooltip title='Current Range' arrow placement="top">
                                            <Typography noWrap align='center' className="fontCss high">C.R: {e?.curr_range ? e?.curr_range : e?.curr_high ? (e?.curr_high - e?.curr_low)?.toFixed(2) : 0}</Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid className="high-low-box">
                                        <Tooltip title='Previous Range' arrow placement="top">
                                            <Typography noWrap align='center' className="fontCss high">P.R: {e?.prev_low ? (e?.prev_high - e?.prev_low)?.toFixed(2) : 0}</Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>)}
            </Grid>
        </>
    )
}

export default TimeFrameContainer