import { Box, FormControl, Grid, List, ListItem, ListItemText, MenuItem, Select, Switch, Tab, TextField, Typography } from '@material-ui/core'
import { KeyboardArrowRight, SearchOutlined } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react'
import AnalysisTables from '../HomePage/AnalysisTables';
import { companyFibonacciData, companyGannData, companyZonesData, fetchDataSelected, fetchFAndOData } from '../apiService/tradaApiService';
import { cam_key_s, cpr_key_s, fpp_key_s, fre_key_s, gann_key_s, spp_key_s, z_key_s } from '../../utils';
import TimeFrameContainer from '../HomePage/TimeFrameContainer';
import { candleGraphFor15min, CurrentCompanyData, fetchThreeRangeBand, GetObservationsForCandle, NiftyPlusPlus } from '../apiService/tickerApiService';
import IndicesStrip from '../common/IndicesStrip';
import { TabContext, TabList } from '@material-ui/lab';
import MetricCandleStick from '../common/MetricCandleStick';
import GaugeChartForDatatable from '../common/GaugeChartForDatatable';
import FutureCandleStickChart from '../common/Futures15Graph';
import dayjs from 'dayjs';
import FutureCandleStickChart30min from '../common/Future30Graph';
import FutureCandleStickChart1hr from '../common/Future1hrGraph';
import FRECandleStickChart from '../common/FRECandleGraph';
import FRECandleStickChart30min from '../common/FRECandleFor30Min';
import FRECandleStickChart1hr from '../common/FRECandle1Hr';
import CandleStickIndices from '../common/CandleStickIndices';
import GraphForCandleStickChart from '../common/GraphForCandleStickChart';
import FREGraphForCandleStickChart from '../common/FREGraphForCandleStickChart';
import withAuth from '../../utils/withAuth';
import GaanGraphForCandleStickChart from '../common/GaanGraphForCandleStickChart';
import { FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material';
import CombinedGraphIndicesandFutures from '../common/CombinedGraphIndicesandFutures';
import IndiceStripNew from '../common/IndiceStripNew';
// import { Radio, RadioGroup, FormControlLabel, FormGroup } from '@mui/material';

const FAndOMenu = [
    { label: "Index Futures", value: "idf" },
    { label: "Stock Futures", value: "stf" },
]

const viewTabs = [
    { value: '1', label: 'Trader View', },
    { value: '2', label: 'Expert View' },
    { value: '3', label: 'Candlestick View' }
]

const timeFramesForDataTable = ["Daily", "Weekly", "Monthly"]

function FutureAndOptions() {
    const [futures, setFutures] = useState('idf');
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);

    const [tickerRes, setTickerRes] = useState({});
    const [closePrice, setClosePrice] = React.useState(0);
    const [curr_perc, setCurr_perc] = React.useState(0);

    const [camarillaData, setCamarilladata] = useState([]);
    const [standardData, setStandarddata] = useState([]);
    const [fibonacciData, setFibonacciData] = useState([]);
    const [cprData, setCprData] = useState([]);
    const [ppData, setPpData] = useState([]);

    const [zonesData, setZonesData] = useState([]);
    const [GannRes, setGannRes] = useState({});
    const [fibonacciRes, setFibonacciRes] = useState({});

    const [prevDC, setPrevDC] = React.useState(0);
    const [prevWC, setPrevWC] = React.useState(0);
    const [prevMC, setPrevMC] = React.useState(0);

    const [cprtlDaily, setCprtlDaily] = React.useState(0);
    const [cprtlWeekly, setCprtlWeekly] = React.useState(0);
    const [cprtlMonthly, setCprtlMonthly] = React.useState(0);

    const [cprblDaily, setCprblDaily] = React.useState(0);
    const [cprblWeekly, setCprblWeekly] = React.useState(0);
    const [cprblMonthly, setCprblMonthly] = React.useState(0);

    const [cam_keys, setCam_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [spp_keys, setSpp_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [fb_keys, setFb_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [cpr_keys, setCpr_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [pp_keys, setPp_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [z_keys, setZ_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [f_keys, setF_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [Gann_keys, setGann_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });

    const [view, setView] = useState("3");
    const [viewFRE, setViewFRE] = useState('gaan');
    const [speedoMeterData_d, setSpeedoData_d] = useState({})
    const [speedoMeterData_w, setSpeedoData_w] = useState({})
    const [speedoMeterData_m, setSpeedoData_m] = useState({})

    const [data15min, setData15min] = useState([]);
    const [data30min, setData30min] = useState([]);
    const [data1hr, setData1hr] = useState([]);
    const [dataYaxis15min_d, setDataYaxis15min_d] = useState([]);
    const [dataYaxis30min_w, setDataYaxis30min_w] = useState([]);
    const [dataYaxis_m, setDataYaxis_m] = useState([]);
    const [keys_d, setKeys_d] = useState([]);
    const [keys_w, setKeys_w] = useState([]);
    const [keys_m, setKeys_m] = useState([]);
    const [p_p_data, setP_p_data] = useState({});

    const [fre_dataYaxis15min_d, setFre_DataYaxis15min_d] = useState([]);
    const [fre_dataYaxis30min_w, setFre_DataYaxis30min_w] = useState([]);
    const [fre_dataYaxis_m, setFre_DataYaxis_m] = useState([]);
    const [fre_keys_d, setFre_Keys_d] = useState([]);
    const [fre_keys_w, setFre_Keys_w] = useState([]);
    const [fre_keys_m, setFre_Keys_m] = useState([]);
    const [fre_p_p_data, setFre_P_p_data] = useState({});
    const [rangeBand, setRangeBand] = React.useState({})
    const [obj_observation_d_w, setObj_Observations_d_w] = useState({
        observe_dailyData: [],
        observe_weeklyData: [],
        observe_monthlyData: [],
    })

    const _getObservations_d_w = async (companySymbol, t) => {
        let _dailyData = await GetObservationsForCandle("D", companySymbol, t)
        let _weeklyData = await GetObservationsForCandle("W", companySymbol, t)
        let _monthlyData = await GetObservationsForCandle("M", companySymbol, t)
        setObj_Observations_d_w({
            observe_dailyData: _dailyData?.data,
            observe_weeklyData: _weeklyData?.data,
            observe_monthlyData: _monthlyData?.data,
        })
    }

    const getCanldesData = async (tok, Symbol) => {
        try {
            const currentDate = dayjs().format('YYYY-MM-DD');
            const [candlesDataforGraph, candlesDataforGraph_30, candlesDataforGraph_60] = await Promise.all([
                candleGraphFor15min(tok, currentDate, currentDate, "15"),
                candleGraphFor15min(tok, currentDate, currentDate, "30"),
                candleGraphFor15min(tok, currentDate, currentDate, "60")
            ]);

            const timeStampData = candlesDataforGraph?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];

            const timeStampData_30 = candlesDataforGraph_30?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];

            const timeStampData_60 = candlesDataforGraph_60?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];
            setData15min(timeStampData);
            setData30min(timeStampData_30);
            setData1hr(timeStampData_60);
        } catch (error) {
            console.error("Error fetching or processing data:", error);
        }
    };

    const handleChange = async (event) => {
        setSearch("");
        setFutures(event.target.value);
        setSpeedoData_d({})
        setSpeedoData_w({})
        setSpeedoData_m({})
        setView('1')
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearch(searchTerm);
        if (searchTerm !== "") {
            const filtered = companyList.filter(company =>
                company.tickerSymbol.toLowerCase().includes(searchTerm) ||
                company.symbol.toLowerCase().includes(searchTerm)
            ).sort((a, b) => {
                if (a.tickerSymbol.toLowerCase().startsWith(searchTerm) && !b.tickerSymbol.toLowerCase().startsWith(searchTerm)) {
                    return -1;
                } else if (!a.tickerSymbol.toLowerCase().startsWith(searchTerm) && b.tickerSymbol.toLowerCase().startsWith(searchTerm)) {
                    return 1;
                } else if (a.symbol.toLowerCase().startsWith(searchTerm) && !b.symbol.toLowerCase().startsWith(searchTerm)) {
                    return -1;
                } else if (!a.symbol.toLowerCase().startsWith(searchTerm) && b.symbol.toLowerCase().startsWith(searchTerm)) {
                    return 1;
                } else {
                    return 0;
                }
            });
            setFilteredCompanies(filtered);
        } else {
            setFilteredCompanies(companyList);
        }
    }

    const onListClick = async (e) => {
        setSelectedItem(e);
    };

    const fetchFANdOCompanies = async (futures) => {
        const data = await fetchFAndOData(futures)
        if (data?.data?.success) {
            setCompanyList(data?.data?.data)
            setFilteredCompanies(data?.data?.data);
            setSelectedItem(data?.data?.data[0]);
        } else {
            setCompanyList([])
            setFilteredCompanies([]);
            setSelectedItem({});
        }
    }

    function filterData(obj, a, b) {
        const entries = Object.entries(obj);
        const filteredAndSorted = entries.filter(([key, value]) => key.startsWith(a) || key.startsWith(b))
            .sort((a, b) => b[1] - a[1]);

        const filteredAndSortedObj = Object.fromEntries(filteredAndSorted);
        return filteredAndSortedObj;
    }

    const fetchSelectedItem = async (obj) => {
        const fetchData = await fetchDataSelected(obj?.symbol)
        if (fetchData?.data?.success) {
            setData(fetchData?.data)
            const cam_data = []
            const spp_data = []
            const fibonacci_data = []
            const cpr_data = []
            const pivortPoints_data = []

            let data = fetchData?.data
            //   for camaralla 
            const camDailyData = filterData(data.dailyData, "CPH", "CPL");
            const dailyCamArr = Object.values(camDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailyCamKeys = Object.keys(camDailyData)

            const camWeeklyData = filterData(data.weeklyData, "CPH", "CPL");
            const weeklyCamArr = Object.values(camWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklyCamKeys = Object.keys(camWeeklyData)

            const camMonthlyData = filterData(data.monthlyData, "CPH", "CPL");
            const monthlyCamArr = Object.values(camMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlyCamKeys = Object.keys(camMonthlyData)

            //   for standard pp
            const sppDailyData = filterData(data.dailyData, "SPH", "SPL");
            const dailySppArr = Object.values(sppDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailySppKeys = Object.keys(sppDailyData)

            const sppWeeklyData = filterData(data.weeklyData, "SPH", "SPL");
            const weeklySppArr = Object.values(sppWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklysppKeys = Object.keys(sppWeeklyData)

            const sppMonthlyData = filterData(data.monthlyData, "SPH", "SPL");
            const monthlySppArr = Object.values(sppMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlySppKeys = Object.keys(sppMonthlyData)

            //  for fibonacci

            const fbDailyData = filterData(data.dailyData, "FPH", "FPL");
            const dailyFbArr = Object.values(fbDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailyFbKeys = Object.keys(fbDailyData)

            const fbWeeklyData = filterData(data.weeklyData, "FPH", "FPL");
            const weeklyFbArr = Object.values(fbWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklyFbKeys = Object.keys(fbWeeklyData)

            const fbMonthlyData = filterData(data.monthlyData, "FPH", "FPL");
            const monthlyFbArr = Object.values(fbMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlyFbKeys = Object.keys(fbMonthlyData)

            //  for CPR

            const CPRDailyData = filterData(data.dailyData, "CPR", "CPR");
            const dailyCPRArr = Object.values(CPRDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailyCPRKeys = Object.keys(CPRDailyData)

            const CPRWeeklyData = filterData(data.weeklyData, "CPR", "CPR");
            const weeklyCPRArr = Object.values(CPRWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklyCPRKeys = Object.keys(CPRWeeklyData)

            const CPRMonthlyData = filterData(data.monthlyData, "CPR", "CPR");
            const monthlyCPRArr = Object.values(CPRMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlyCPRKeys = Object.keys(CPRMonthlyData)

            // for all pivort points

            let dailyArr = Object.values(data.dailyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            let _dailyKeys = Object.keys(data.dailyData)

            let weeklyArr = Object.values(data.weeklyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            let _weeklyKeys = Object.keys(data.weeklyData)

            let monthlyArr = Object.values(data.monthlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            let _monthlyKeys = Object.keys(data.monthlyData)

            dailyArr.map((e, i) => {
                pivortPoints_data.push({ daily: e, weekly: weeklyArr[i], monthly: monthlyArr[i] })
            })

            dailyCamArr.map((e, i) => {
                cam_data.push({ daily: e, weekly: weeklyCamArr[i], monthly: monthlyCamArr[i] })
            })

            dailySppArr.map((e, i) => {
                spp_data.push({ daily: e, weekly: weeklySppArr[i], monthly: monthlySppArr[i] })
            })
            dailyFbArr.map((e, i) => {
                fibonacci_data.push({ daily: e, weekly: weeklyFbArr[i], monthly: monthlyFbArr[i] })
            })
            dailyCPRArr.map((e, i) => {
                cpr_data.push({ daily: e, weekly: weeklyCPRArr[i], monthly: monthlyCPRArr[i] })
            })

            setCamarilladata(cam_data)
            setStandarddata(spp_data)
            setFibonacciData(fibonacci_data)
            setCprData(cpr_data)
            setPpData(pivortPoints_data)

            setPrevDC(data?.pre_CLOSE_DAY[0]?.PRE_CLOSE_DAY)
            setPrevWC(data?.pre_CLOSE_WEEK[0]?.PRE_CLOSE_WEEK)
            setPrevMC(data?.pre_CLOSE_MONTH[0]?.PRE_CLOSE_MONTH)

            setCprtlDaily(data?.dailyData?.CPRTL_D)
            setCprtlWeekly(data?.weeklyData?.CPRTL_W)
            setCprtlMonthly(data?.monthlyData?.CPRTL_M)

            setCprblDaily(data?.dailyData?.CPRBL_D)
            setCprblWeekly(data?.weeklyData?.CPRBL_W)
            setCprblMonthly(data?.monthlyData?.CPRBL_M)

            setCam_Keys({ dailyKeys: _dailyCamKeys, weeklyKeys: _weeklyCamKeys, monthlyKeys: _monthlyCamKeys })
            setSpp_Keys({ dailyKeys: _dailySppKeys, weeklyKeys: _weeklysppKeys, monthlyKeys: _monthlySppKeys })
            setFb_Keys({ dailyKeys: _dailyFbKeys, weeklyKeys: _weeklyFbKeys, monthlyKeys: _monthlyFbKeys })
            setCpr_Keys({ dailyKeys: _dailyCPRKeys, weeklyKeys: _weeklyCPRKeys, monthlyKeys: _monthlyCPRKeys })
            setPp_Keys({ dailyKeys: _dailyKeys, weeklyKeys: _weeklyKeys, monthlyKeys: _monthlyKeys })

            setP_p_data(fetchData?.data)
            setDataYaxis15min_d(Object.values(fetchData?.data?.dailyData || {}));
            setDataYaxis30min_w(Object.values(fetchData?.data?.weeklyData || {}));
            setDataYaxis_m(Object.values(fetchData?.data?.monthlyData || {}));
            setKeys_d(Object.keys(fetchData?.data?.dailyData || {}))
            setKeys_w(Object.keys(fetchData?.data?.weeklyData || {}))
            setKeys_m(Object.keys(fetchData?.data?.monthlyData || {}))
        } else {
            setCamarilladata([])
        }
    }
    const fetchGannData = async (selectedItem) => {
        let GannData = []
        const GannResponse = await companyGannData(selectedItem?.symbol)
        if (GannResponse?.data?.success) {

            let gann_data = GannResponse?.data

            let Gann_D_dailyArr = Object.values(gann_data?.dailyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Gannb_D_dailyKeys = Object.keys(gann_data?.dailyData)

            let Gann_D_weeklyArr = Object.values(gann_data?.weeklyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Gannb_D_weeklyKeys = Object.keys(gann_data?.weeklyData)

            let Gann_D_monthlyArr = Object.values(gann_data?.monthlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Gannb_D_monthlyKeys = Object.keys(gann_data?.monthlyData)

            Gann_D_dailyArr.map((e, i) => {
                GannData.push({ daily: e, weekly: Gann_D_weeklyArr[i], monthly: Gann_D_monthlyArr[i] })
            })
            setGann_Keys({ dailyKeys: Gannb_D_dailyKeys, weeklyKeys: Gannb_D_weeklyKeys, monthlyKeys: Gannb_D_monthlyKeys, })
            setGannRes(GannData)
        }
    }
    const fetchZones = async (selectedItem) => {
        let zdata = [];
        const zonesres = await companyZonesData(selectedItem?.symbol)
        if (zonesres?.data?.success) {
            setZonesData(zonesres?.data?.data)

            let d_z_data = filterData(zonesres?.data?.data, "d", "d");
            const dailyZArr = Object.values(d_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            const _dailyZKeys = Object.keys(d_z_data)

            let w_z_data = filterData(zonesres?.data?.data, "w", "w");
            const weeklyZArr = Object.values(w_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            const _weeklyZKeys = Object.keys(w_z_data)

            let m_z_data = filterData(zonesres?.data?.data, "m", "m");
            const monthlyZArr = Object.values(m_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            const _monthlyZKeys = Object.keys(m_z_data)

            dailyZArr.map((e, i) => {
                zdata.push({ daily: e, weekly: weeklyZArr[i], monthly: monthlyZArr[i] })
            })

            setZ_Keys({ dailyKeys: _dailyZKeys, weeklyKeys: _weeklyZKeys, monthlyKeys: _monthlyZKeys, })
            setZonesData(zdata)
        } else {
            setZonesData([])
        }
    }
    const fetchFibonacciData = async (selectedItem) => {
        let fibonacciData = []
        const fibonacciRes = await companyFibonacciData(selectedItem?.symbol)

        if (fibonacciRes?.data?.success) {

            let Fib_data = fibonacciRes?.data

            let Fib_D_dailyArr = Object.values(Fib_data?.dailyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Fib_D_dailyKeys = Object.keys(Fib_data?.dailyData)

            let Fib_D_weeklyArr = Object.values(Fib_data?.weeklyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Fib_D_weeklyKeys = Object.keys(Fib_data?.weeklyData)

            let Fib_D_monthlyArr = Object.values(Fib_data?.monthlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Fib_D_monthlyKeys = Object.keys(Fib_data?.monthlyData)

            Fib_D_dailyArr.map((e, i) => {
                fibonacciData.push({ daily: e, weekly: Fib_D_weeklyArr[i], monthly: Fib_D_monthlyArr[i] })
            })
            setF_Keys({ dailyKeys: Fib_D_dailyKeys, weeklyKeys: Fib_D_weeklyKeys, monthlyKeys: Fib_D_monthlyKeys, })
            setFibonacciRes(fibonacciData)

            setFre_DataYaxis15min_d(Object.values(fibonacciRes?.data?.dailyData || {}))
            setFre_DataYaxis30min_w(Object.values(fibonacciRes?.data?.weeklyData || {}))
            setFre_DataYaxis_m(Object.values(fibonacciRes?.data?.monthlyData || {}))
            setFre_Keys_d(Object.keys(fibonacciRes?.data?.dailyData || {}))
            setFre_Keys_w(Object.keys(fibonacciRes?.data?.weeklyData || {}))
            setFre_Keys_m(Object.keys(fibonacciRes?.data?.monthlyData || {}))
        }
    }

    const currentMP = async (token) => {
        const CMPdata = await CurrentCompanyData(token);
        setTickerRes(CMPdata?.data)
        setClosePrice(CMPdata?.data?.closePrice)
        setCurr_perc((CMPdata?.data?.change)?.toFixed(2))
    }

    const fetchDataForSpeedometer = async (token, companySymbol) => {
        const company_speedo_data = await NiftyPlusPlus(companySymbol, token)
        setSpeedoData_d(company_speedo_data?.data?.daily[0])
        setSpeedoData_w(company_speedo_data?.data?.weekly[0])
        setSpeedoData_m(company_speedo_data?.data?.monthly[0])
    }
    const RangeBand = async () => {
        const currentDate = dayjs().format('YYYY-MM-DD');
        const tomorrowDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        const tenDaysBefore = dayjs().subtract(10, 'day').format('YYYY-MM-DD');
        const _RangeBound = await fetchThreeRangeBand(tenDaysBefore, tomorrowDate, selectedItem?.instrument_token);
        setRangeBand(_RangeBound?.data)
    }

    useEffect(() => {
        fetchFANdOCompanies(futures)
    }, [futures])

    useEffect(() => {
        if (selectedItem?.instrument_token) {
            fetchSelectedItem(selectedItem)
            fetchFibonacciData(selectedItem)
            fetchZones(selectedItem)
            fetchGannData(selectedItem)
            const observationsInterVal_d_w = setInterval(() => {
                if (selectedItem?.symbol && selectedItem?.instrument_token) {
                    _getObservations_d_w(selectedItem?.symbol, selectedItem?.instrument_token)
                }
            }, 30000);
            const intervalId = setInterval(() => {
                currentMP(selectedItem?.instrument_token)
                getCanldesData(selectedItem?.instrument_token, selectedItem?.symbol)
                fetchDataForSpeedometer(selectedItem?.instrument_token, selectedItem?.symbol)
            }, 2000)
            return () => {
                clearInterval(intervalId)
                clearInterval(observationsInterVal_d_w)
            }
        }
    }, [selectedItem, view])

    useMemo(() => {
        if (selectedItem?.symbol && selectedItem?.instrument_token) {
            _getObservations_d_w(selectedItem?.symbol, selectedItem?.instrument_token)
            fetchDataForSpeedometer(selectedItem?.instrument_token, selectedItem?.symbol)
            RangeBand()
        }
    }, [selectedItem, view])

    const getPercentage = (i) => {
        switch (i) {
            case 0:
                let prec_D = ((tickerRes?.lastTradedPrice - prevDC) / prevDC) * 100
                let D_points = (tickerRes?.lastTradedPrice - closePrice)?.toFixed(2)
                return <>
                    <p style={{ fontSize: '11px', color: `${D_points >= 0 ? "#339249" : "#DA2424"}` }}>{D_points ? D_points : 0}</p>
                    <p style={{ fontSize: '11px', color: `${D_points >= 0 ? "#339249" : "#DA2424"}` }}>{`(${curr_perc ? curr_perc : 0}%)`}</p>
                </>
            case 1:
                let prec_W = ((tickerRes?.lastTradedPrice - prevWC) / prevWC) * 100
                let W_points = (tickerRes?.lastTradedPrice - prevWC)?.toFixed(2)
                return <>
                    <p style={{ fontSize: '11px', color: `${W_points >= 0 ? "#339249" : "#DA2424"}` }}>{W_points ? W_points : 0}</p>
                    <p style={{ fontSize: '11px', color: `${prec_W >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_W ? prec_W.toFixed(2) : 0}%)`}</p>
                </>
            case 2:
                let prec_M = ((tickerRes?.lastTradedPrice - prevMC) / prevMC) * 100
                let M_points = (tickerRes?.lastTradedPrice - prevMC)?.toFixed(2)
                return <>
                    <p style={{ fontSize: '11px', color: `${M_points >= 0 ? "#339249" : "#DA2424"}` }}>{M_points ? M_points : 0}</p>
                    <p style={{ fontSize: '11px', color: `${prec_M >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_M ? prec_M.toFixed(2) : 0}%)`}</p>
                </>
            default:
                return <p>0</p>
        }
    }

    const getObservations = (tf) => {
        switch (tf) {
            case "D":
                let d_obser = {
                    arrow: obj_observation_d_w?.observe_dailyData[0]?.arrow, poscount: obj_observation_d_w?.observe_dailyData[0]?.poscount, negcount: obj_observation_d_w?.observe_dailyData[0]?.negcount,
                    neutralcount: obj_observation_d_w?.observe_dailyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_dailyData[0]?.totalMetrics, rest_data: obj_observation_d_w?.observe_dailyData[0]
                }
                return d_obser
            case "W":
                let w_obser = {
                    arrow: obj_observation_d_w?.observe_weeklyData[0]?.arrow, poscount: obj_observation_d_w?.observe_weeklyData[0]?.poscount, negcount: obj_observation_d_w?.observe_weeklyData[0]?.negcount,
                    neutralcount: obj_observation_d_w?.observe_weeklyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_weeklyData[0]?.totalMetrics, rest_data: obj_observation_d_w?.observe_weeklyData[0]
                }
                return w_obser
            case "M":
                let m_obser = {
                    arrow: obj_observation_d_w?.observe_monthlyData[0]?.arrow, poscount: obj_observation_d_w?.observe_monthlyData[0]?.poscount, negcount: obj_observation_d_w?.observe_monthlyData[0]?.negcount,
                    neutralcount: obj_observation_d_w?.observe_monthlyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_monthlyData[0]?.totalMetrics, rest_data: obj_observation_d_w?.observe_monthlyData[0]
                }
                return m_obser
            default:
                return {};
        }
    }

    const timeFrames = [
        {
            timeFrame: "Daily",
            curr_open: tickerRes?.openPrice?.toFixed(2),
            curr_high: tickerRes?.highPrice?.toFixed(2),
            curr_low: tickerRes?.lowPrice?.toFixed(2),
            curr_range: (tickerRes?.highPrice - tickerRes?.lowPrice)?.toFixed(2),
            prev_Range_perc: data?.dailyLowHigh?.length > 0 ? (((tickerRes?.lastTradedPrice - data?.dailyLowHigh[0]?.pre_low) / (data?.dailyLowHigh[0]?.pre_high - data?.dailyLowHigh[0]?.pre_low))?.toFixed(2) * 100)?.toFixed(2) : 0,
            prev_high: data?.dailyLowHigh?.length > 0 ? data?.dailyLowHigh[0]?.pre_high?.toFixed(2) : 0,
            prev_low: data?.dailyLowHigh?.length > 0 ? data?.dailyLowHigh[0]?.pre_low?.toFixed(2) : 0,
            prev_close: data?.pre_CLOSE_DAY?.length > 0 ? data?.pre_CLOSE_DAY[0]?.PRE_CLOSE_DAY?.toFixed(2) : 0,
            percentage: getPercentage(0),
            observations: getObservations("D")
        },
        {
            timeFrame: "Weekly",
            curr_open: data?.open_WEEK?.length > 0 ? data?.open_WEEK[0]?.OPEN_WEEK?.toFixed(2) : 0,
            curr_high: data.weeklyLowHigh?.length > 0 ? (tickerRes ? (data.weeklyLowHigh[0].high > tickerRes?.highPrice ? data.weeklyLowHigh[0].high : tickerRes?.highPrice) : data.weeklyLowHigh[0].high)?.toFixed(2) : 0,
            curr_low: data.weeklyLowHigh?.length > 0 ? (tickerRes ? (data.weeklyLowHigh[0].low < tickerRes?.lowPrice ? (data.weeklyLowHigh[0].low == 0 ? tickerRes?.lowPrice : data.weeklyLowHigh[0].low) : tickerRes?.lowPrice) : data.weeklyLowHigh[0].low)?.toFixed(2) : 0,
            // curr_range: data.weeklyLowHigh?.length > 0 ? (tickerRes ? (data.weeklyLowHigh[0].high > tickerRes?.highPrice ? data.weeklyLowHigh[0].high : tickerRes?.highPrice) : data.weeklyLowHigh[0].high)?.toFixed(2) - (tickerRes ? (data.weeklyLowHigh[0].low < tickerRes?.lowPrice ? (data.weeklyLowHigh[0].low == 0 ? tickerRes?.lowPrice : data.weeklyLowHigh[0].low) : tickerRes?.lowPrice) : data.weeklyLowHigh[0].low)?.toFixed(2) : 0,
            prev_Range_perc: data?.pre_weeklyLowHigh?.length > 0 ? ((((tickerRes?.lastTradedPrice - data?.pre_weeklyLowHigh[0]?.pre_low) / (data?.pre_weeklyLowHigh[0]?.pre_high - data?.pre_weeklyLowHigh[0]?.pre_low))?.toFixed(2)) * 100)?.toFixed(2) : 0,
            prev_high: data?.pre_weeklyLowHigh?.length > 0 ? data?.pre_weeklyLowHigh[0]?.pre_high?.toFixed(2) : 0,
            prev_low: data?.pre_weeklyLowHigh?.length > 0 ? data?.pre_weeklyLowHigh[0]?.pre_low?.toFixed(2) : 0,
            prev_close: data?.pre_CLOSE_WEEK?.length > 0 ? data?.pre_CLOSE_WEEK[0]?.PRE_CLOSE_WEEK?.toFixed(2) : 0,
            percentage: getPercentage(1),
            observations: getObservations("W")
        },
        {
            timeFrame: "Monthly",
            curr_open: data?.open_MONTH?.length > 0 ? data?.open_MONTH[0]?.OPEN_MONTH?.toFixed(2) : 0,
            curr_high: data.monthlyLowHigh?.length > 0 ? (tickerRes ? (data.monthlyLowHigh[0].high > tickerRes.highPrice ? data.monthlyLowHigh[0].high : tickerRes.highPrice) : data.monthlyLowHigh[0].high)?.toFixed(2) : 0,
            curr_low: data.monthlyLowHigh?.length > 0 ? (tickerRes ? (data.monthlyLowHigh[0].low < tickerRes.lowPrice ? (data.monthlyLowHigh[0].low == 0 ? tickerRes.lowPrice : data.monthlyLowHigh[0].low) : tickerRes.lowPrice) : data.monthlyLowHigh[0].low)?.toFixed(2) : 0,
            // curr_range: data.monthlyLowHigh?.length > 0 ? (tickerRes ? (data.monthlyLowHigh[0].high > tickerRes.highPrice ? data.monthlyLowHigh[0].high : tickerRes.highPrice) : data.monthlyLowHigh[0].high)?.toFixed(2) - (tickerRes ? (data.monthlyLowHigh[0].low < tickerRes.lowPrice ? (data.monthlyLowHigh[0].low == 0 ? tickerRes.lowPrice : data.monthlyLowHigh[0].low) : tickerRes.lowPrice) : data.monthlyLowHigh[0].low)?.toFixed(2) : 0,
            prev_Range_perc: data?.pre_monthlyLowHigh?.length > 0 ? (((tickerRes?.lastTradedPrice - data?.pre_monthlyLowHigh[0]?.pre_low) / (data?.pre_monthlyLowHigh[0]?.pre_high - data?.pre_monthlyLowHigh[0]?.pre_low))?.toFixed(2) * 100)?.toFixed(2) : 0,
            prev_high: data?.pre_monthlyLowHigh?.length > 0 ? data?.pre_monthlyLowHigh[0]?.pre_high?.toFixed(2) : 0,
            prev_low: data?.pre_monthlyLowHigh?.length > 0 ? data?.pre_monthlyLowHigh[0]?.pre_low?.toFixed(2) : 0,
            prev_close: data?.pre_CLOSE_MONTH?.length > 0 ? data?.pre_CLOSE_MONTH[0]?.PRE_CLOSE_MONTH?.toFixed(2) : 0,
            percentage: getPercentage(2),
            observations: getObservations("M")
        },
    ]

    const handleChangeView = (e, v) => {
        setView(v)
    }

    const getPerc = (cmp, pc) => {
        let a = ((cmp - pc) / pc) * 100
        return a?.toFixed(1)
    }

    const onViewClick = (e) => {
        console.log('....', e.target.value);

        setViewFRE(e.target.value)
    }

    const getHold = (v) => {
        if (v <= 20) return "Bearish++"
        else if (v > 20 && v <= 40) return "Bearish+"
        else if (v > 40 && v <= 60) return "Neutral"
        else if (v > 60 && v <= 80) return "Bullish+"
        else if (v > 80) return "Bullish++"
    }
    const getColor = (v) => {
        if (v <= 20) return "#da2424"
        else if (v > 20 && v <= 40) return "#f74f4f"
        else if (v > 40 && v <= 60) return "#ffb200e3"
        else if (v > 60 && v <= 80) return "#44db67"
        else if (v > 80) return "#339249"

    }

    const getShortAndLong = (l_prec, prev_range, previousClose, open) => {
        // debugger
        let percentage = (prev_range + l_prec) / 2
        if ((percentage <= 38.2 && ((tickerRes?.lastTradedPrice < previousClose) || (tickerRes?.lastTradedPrice < open)))) {
            return 'No Long';
        }
        else if ((percentage >= 61.8 && ((tickerRes?.lastTradedPrice > previousClose) || (tickerRes?.lastTradedPrice > open)))) {
            return 'No Short'
        }
        else {
            return 'No Action';
        }

    }
    const getShortAndLongColor = (l_prec, prev_range, previousClose, open) => {
        let percentage = (prev_range + l_prec) / 2
        if ((percentage <= 38.2 && ((tickerRes?.lastTradedPrice < previousClose) || (tickerRes?.lastTradedPrice < open)))) return '#da2424';
        else if ((percentage >= 61.8 && ((tickerRes?.lastTradedPrice > previousClose) || (tickerRes?.lastTradedPrice > open)))) return '#339249';
        else {
            return '#ffa100e3';
        }
    }
    const graphHead = (sd, dur) => {
        return (
            <>
                {/* <Grid className='' style={{ background: '#fff', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Grid>  <Typography style={{ color: getColor(sd?.percentage?.toFixed(2)), marginLeft: "3px", alignItems: 'center', display: 'flex' }} className='font-futura'>
                        <span style={{ fontFamily: 'Futura PT Heavy', color: '#000', fontSize: '13px' }}>{dur}&nbsp;</span>
                        <span style={{ fontFamily: 'Futura PT Heavy', fontSize: "18px" }}> {getHold(sd?.percentage?.toFixed(2))}</span>
                        <span style={{ fontSize: '13px', marginLeft: '5px' }}> ({sd?.percentage?.toFixed(2)}%)</span>
                    </Typography></Grid>
                    <Grid style={{ width: '50%', zIndex: '99', left: '25%' }}>
                        <CandleStickIndices percentage={sd?.percentage} open={sd?.current_open ? sd?.current_open : sd?.ticker_open}
                            high={sd?.current_high >= sd?.ticker_high ? sd?.current_high : sd?.ticker_high}
                            low={sd?.current_low >= sd?.ticker_low ? sd?.ticker_low : sd?.current_low == 0 ? sd?.ticker_low : sd?.current_low}
                            close={sd?.current_close} poscount={sd?.poscount} negcount={sd?.negcount} arrow={sd?.arrow} totalMetrics={sd?.totalMetrics} prev_range={sd?.pre_percentage}

                        />
                    </Grid>
                </Grid> */}
                <Grid style={{ background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>   <Typography style={{ color: getColor(sd?.percentage?.toFixed(2)), marginLeft: "3px", alignItems: 'center', display: 'flex' }} className='font-futura'>
                    <span style={{ fontFamily: 'Futura PT Heavy', color: '#000', fontSize: '13px' }}>{dur}&nbsp;</span>
                    <span style={{ fontFamily: 'Futura PT Heavy', fontSize: "18px" }}> {getHold(sd?.percentage?.toFixed(2))}</span>
                    <span style={{ fontSize: '13px', marginLeft: '5px' }}> ({sd?.percentage?.toFixed(2)}%)</span>
                    <span align='center' style={{ color: getShortAndLongColor(sd?.current_percentage, sd?.pre_percentage, sd?.current_close, sd?.current_open), marginTop: "-0.3rem", fontWeight: "bold", fontSize: "12px" }}>{getShortAndLong(sd?.current_percentage, sd?.pre_percentage, sd?.current_close, sd?.current_open)}</span>
                </Typography></Grid>
                <Grid className='flex' style={{ justifyContent: 'space-around', alignItems: 'center', backgroundColor: '#fff' }}>
                    <Grid className='flex'>
                        <Typography style={{ color: '#da2727', fontFamily: 'Futura PT Book', background: '#fff', borderRadius: "5px", padding: '0px 4px', fontWeight: 'Bold', fontSize: '11px' }}><span style={{ color: '#154a72' }}>ATRLB :</span> <span style={{ fontSize: '12px' }}>{rangeBand?.ATRLB?.toFixed(2)}</span></Typography>
                        <Typography style={{ color: '#4d8fcc', fontFamily: 'Futura PT Book', background: '#fff', borderRadius: "5px", padding: '0px 4px', fontWeight: 'Bold', fontSize: '11px' }}><span style={{ color: '#154a72' }}>ATRMB :</span> <span style={{ fontSize: '12px' }}>{rangeBand?.ATRMB?.toFixed(2)}</span></Typography>
                        <Typography style={{ color: '#339249', fontFamily: 'Futura PT Book', background: '#fff', borderRadius: "5px", padding: '0px 4px', fontWeight: 'Bold', fontSize: '11px' }}><span style={{ color: '#154a72' }}>ATRUB :</span> <span style={{ fontSize: '12px' }}>{rangeBand?.ATRUB?.toFixed(2)}</span></Typography>
                    </Grid>
                    <Grid style={{ width: '50%', zIndex: '99', left: '25%' }}>
                        <CandleStickIndices percentage={sd?.percentage} open={sd?.current_open ? sd?.current_open : sd?.ticker_open}
                            high={sd?.current_high >= sd?.ticker_high ? sd?.current_high : sd?.ticker_high}
                            low={sd?.current_low >= sd?.ticker_low ? sd?.ticker_low : sd?.current_low == 0 ? sd?.ticker_low : sd?.current_low}
                            close={sd?.current_close} poscount={sd?.poscount} negcount={sd?.negcount} arrow={sd?.arrow} totalMetrics={sd?.totalMetrics} prev_range={sd?.pre_percentage}

                        />
                    </Grid>
                </Grid>
            </>
        )
    }

    const graphHead_wm = (sd, dur, mb) => (
        <>
            <Grid style={{ background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography style={{ color: getColor(sd?.percentage?.toFixed(2)), marginLeft: "3px", alignItems: 'center', display: 'flex' }} className='font-futura'>
                    <span style={{ fontFamily: 'Futura PT Heavy', color: '#000', fontSize: '13px' }}>{dur}&nbsp; </span>
                    <span style={{ fontFamily: 'Futura PT Heavy', fontSize: "18px" }}> {getHold(sd?.percentage?.toFixed(2))}</span>
                    <span style={{ fontSize: '13px', marginLeft: '5px' }}>  ({sd?.percentage?.toFixed(2)}%)</span>
                    <span align='center' style={{ color: getShortAndLongColor(sd?.current_percentage, sd?.pre_percentage, sd?.current_close, sd?.current_open), marginTop: "-0.3rem", fontWeight: "bold", fontSize: "12px" }}>{getShortAndLong(sd?.current_percentage, sd?.pre_percentage, sd?.current_close, sd?.current_open)}</span>
                </Typography>
            </Grid>
            <Grid style={{ marginBottom: mb ? "-0.5rem" : "" }}>
                <CandleStickIndices percentage={sd?.percentage} open={sd?.current_open ? sd?.current_open : sd?.ticker_open}
                    high={sd?.current_high >= sd?.ticker_high ? sd?.current_high : sd?.ticker_high}
                    low={sd?.current_low >= sd?.ticker_low ? sd?.ticker_low : sd?.current_low == 0 ? sd?.ticker_low : sd?.current_low}
                    close={sd?.current_close} poscount={sd?.poscount} negcount={sd?.negcount} arrow={sd?.arrow} totalMetrics={sd?.totalMetrics} prev_range={sd?.pre_percentage}

                />
            </Grid>
        </>
    )


    return (<>
        <Box className='w-2/12'>
            <FormControl className='select-dropdown' >
                <Select value={futures} onChange={handleChange} style={{ fontFamily: "Futura PT Book", fontWeight: "bold", display: 'flex', width: '100%' }} labelId="demo-simple-select-label" id="demo-simple-select">
                    {FAndOMenu.map((e, i) => (
                        <MenuItem value={e.value} key={i} style={{ fontSize: "13px", fontFamily: "Futura PT Book", borderBottom: "1px solid lightgrey" }}>{e.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField value={search} onChange={handleSearch} className='list-search' id="outlined-search" type="search" placeholder='Search For Companies' InputProps={{ startAdornment: <SearchOutlined /> }} />
            <List style={{ overflow: 'auto', padding: '0px', }}>
                {filteredCompanies.map((item, i) => (
                    <ListItem className='listitem' style={{ backgroundColor: `${selectedItem?.symbol === item?.symbol ? "#b2d5f1" : "#fff"}` }} button={true} key={i} onClick={() => onListClick(item)}>
                        <Grid className='list-content'>
                            <ListItemText className='list-csymbol' primary={item.symbol} />
                            <ListItemText className='list-cname' primary={item.tickerSymbol} />
                        </Grid>
                        <KeyboardArrowRight />
                    </ListItem>
                ))}
            </List>
        </Box>
        <Box className='w-10/12'>
            <Grid className="table-ticker">
                <Grid className='company-cmp'>
                    <Grid className="details">
                        <Grid className="details-context px-2 py-1"
                            style={{ color: '#fff', marginLeft: '0px' }}>
                            {selectedItem?.symbol}
                        </Grid>
                    </Grid>
                    <Grid >
                        <Grid className='pricing m-2'>
                            <Grid style={{ color: `${closePrice <= tickerRes?.lastTradedPrice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, display: "flex", alignItems: "center", backgroundColor: '#fff', padding: '0px 13px', borderRadius: '4px', }}>
                                <p className='mr-1'>{tickerRes?.lastTradedPrice ? tickerRes?.lastTradedPrice : 0}</p>
                                <p style={{ fontSize: '11px', color: '#857d7d' }}>{tickerRes ? (tickerRes?.lastTradedPrice - tickerRes?.closePrice)?.toFixed(2) : 0}</p>
                                <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${tickerRes.change ? (tickerRes.change)?.toFixed(2) : 0}%)`}</p>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid className='flex'>
                    {/* <IndicesStrip token="256265" IndiceLabel="NIFTY 50" />
                    <IndicesStrip token="260105" IndiceLabel="BANK NIFTY" />
                    <IndicesStrip token="259849" IndiceLabel="IT NIFTY" /> */}
                    <IndiceStripNew showNifty50={true} showBankNifty={true} showITNifty={true}/>
                </Grid>
            </Grid>
            {view === "1" &&
                <>
                    <Grid className='trading-ticker'>
                        <TimeFrameContainer timeFrames={timeFrames} tickerData={tickerRes} />
                    </Grid>
                    <Grid className="invest-tables" style={{ height: futures === "idf" ? 'calc(100vh - 250px)' : "calc(100vh - 245px)", overflow: 'auto', top: '106px' }}>
                        <Grid className='etf-col'>
                            <AnalysisTables value={2} heading="Camarilla Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={camarillaData} keys={cam_keys} h_l_keys={cam_key_s} />
                            <AnalysisTables value={2} heading="Standard Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={standardData} keys={spp_keys} h_l_keys={spp_key_s} />
                            <AnalysisTables value={2} heading="Zones Analysis" timeFramesForDataTable={timeFramesForDataTable} data={zonesData} keys={z_keys} zones={true} h_l_keys={z_key_s} />
                        </Grid>
                        <Grid className='etf-col'>
                            <AnalysisTables value={2} heading="All Pivot Points Analysis" timeFramesForDataTable={timeFramesForDataTable} data={ppData} keys={pp_keys} />
                            <AnalysisTables value={2} heading="Central Pivot Range (CPR) Analysis" timeFramesForDataTable={timeFramesForDataTable} data={cprData} keys={cpr_keys} h_l_keys={cpr_key_s} />
                        </Grid>
                        <Grid className='etf-col'>
                            <AnalysisTables value={2} heading="Fibonacci Retracement & Extension Analysis" timeFramesForDataTable={timeFramesForDataTable} data={fibonacciRes} keys={f_keys} h_l_keys={fre_key_s} fibonacci={true} />
                            <AnalysisTables value={2} heading="Fibonacci Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={fibonacciData} keys={fb_keys} h_l_keys={fpp_key_s} />
                        </Grid>
                        <Grid className='etf-col'>
                            <AnalysisTables value={2} heading="Gann Analysis" timeFramesForDataTable={timeFramesForDataTable} data={GannRes} keys={Gann_keys} h_l_keys={gann_key_s} Gaan={true} />
                        </Grid>
                    </Grid>
                </>
            }
            {
                view === "2" &&
                <>
                    <Box className='flex gap-2'>
                        {[speedoMeterData_d, speedoMeterData_w, speedoMeterData_m]?.map((s, index) => {
                            return (
                                <>
                                    <Grid className='border-timeframe border-b-2 mt-1 ml-2' key={index} style={{ width: "31%" }}>
                                        <Grid className=' header-timeframe'>
                                            <Typography align='center' className='font-black font-futura' style={{ fontSize: '14px' }}>{timeFramesForDataTable[index]}</Typography>
                                        </Grid>
                                        <Grid className='nse-border' key={index}>
                                            <GaugeChartForDatatable value={s?.percentage?.toFixed(2)} open={s?.current_open ? s?.current_open : s?.ticker_open} high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low}
                                                close={s?.current_close} prev_range={Number.isFinite(s?.pre_percentage) ? s?.pre_percentage : 0}
                                                cmp={s?.currentprice} curr_range={s?.current_percentage} previousclose={s?.pre_close}
                                            />
                                            {/* <GaugeChartForDatatable value={s?.percentage?.toFixed(2)}high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low}  open={s?.current_open ? s?.current_open : s?.ticker_open} close={s?.current_close} prev_range={Number.isFinite(s?.pre_percentage) ? s?.pre_percentage : 0}/> */}
                                            <Grid className="flex justify-center">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ color: `${s?.currentprice > s?.pre_close ? "#000066" : "#DA2424"}`, marginTop: '25px' }}>{s?.index}
                                                    <span className="flex items-center" style={{ color: "", fontSize: '12px', marginLeft: '3px' }}>{s?.currentprice}</span>
                                                    <span className="flex items-center" style={{ color: "", fontSize: '10px' }}>({(s?.currentprice - s?.pre_close).toFixed(2)})({getPerc(s?.currentprice, s?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid className='standard-observ-indices'>
                                                <MetricCandleStick percentage={s?.percentage} open={s?.current_open ? s?.current_open : s?.ticker_open} high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low}
                                                    close={s?.current_close} poscount={s?.poscount} negcount={s?.negcount} arrow={s?.arrow} totalMetrics={s?.totalMetrics} prev_range={Number.isFinite(s?.pre_percentage) ? s?.pre_percentage : 0}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid className='im-ohlc flex w-full mb-2 mt-2'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high) - (s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.pre_high - s?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.pre_low) ? (s?.pre_high - s?.pre_low)?.toFixed(1) : 0}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.current_open == 0 ? s?.ticker_open : s?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.current_high >= s?.ticker_high ? s?.current_high?.toFixed(1) : s?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.current_low >= s?.ticker_low ? s?.ticker_low?.toFixed(1) : s?.current_low == "0" ? s?.ticker_low?.toFixed(1) : s?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}
                    </Box>
                </>
            }
            {
                view === "3" &&
                <>
                    {/* {viewFRE === "indice" &&
                        <Box className='w-full flex gap-1'>
                            <Grid style={{ width: "55%",border:'1px solid lightgray',borderRadius:'5px' }}>
                                {Object.values(speedoMeterData_d)?.length && graphHead(speedoMeterData_d, "15 Mins")}
                                <GraphForCandleStickChart token={selectedItem?.instrument_token} min="15" companySymbol={selectedItem?.symbol} tf="dailyData" ht="341" />
                            </Grid>
                            <Grid style={{ width: "23%",border:'1px solid lightgray',borderRadius:'5px' }}>
                                {Object.values(speedoMeterData_w)?.length && graphHead_wm(speedoMeterData_w, "30 Mins", false)}
                                <GraphForCandleStickChart token={selectedItem?.instrument_token} min="30" companySymbol={selectedItem?.symbol} tf="weeklyData" ht='344px' />
                            </Grid>
                            <Grid style={{ width: "20%",borderLeft:'1px solid lightgray',borderRadius:'5px' }}>
                                {Object.values(speedoMeterData_m)?.length && graphHead_wm(speedoMeterData_m, "1 Hr", false)}
                                <GraphForCandleStickChart token={selectedItem?.instrument_token} min="60" companySymbol={selectedItem?.symbol} tf="monthlyData" ht="344" />
                            </Grid>
                        </Box>
                    } */}
                    {/* {
                        viewFRE === "fre" &&
                        <Box className='w-full flex gap-1'>
                            <Grid style={{ width: "55%",border:'1px solid lightgray',borderRadius:'5px' }}>
                                {Object.values(speedoMeterData_d)?.length && graphHead(speedoMeterData_d, "15 Mins")}
                                <FREGraphForCandleStickChart token={selectedItem?.instrument_token} min="15" companySymbol={selectedItem?.symbol} tf="dailyData" ht="340" />
                            </Grid>
                            <Grid style={{ width: "23%",border:'1px solid lightgray',borderRadius:'5px' }}>
                                {Object.values(speedoMeterData_w)?.length && graphHead_wm(speedoMeterData_w, "30 Mins", true)}
                                <Grid style={{ height: "0.2rem", backgroundColor: "#fff" }}></Grid>
                                <FREGraphForCandleStickChart token={selectedItem?.instrument_token} min="30" companySymbol={selectedItem?.symbol} tf="weeklyData" ht="350" />
                            </Grid>
                            <Grid style={{ width: "20%",borderLeft:'1px solid lightgray',borderRadius:'5px' }}>
                                {Object.values(speedoMeterData_m)?.length && graphHead_wm(speedoMeterData_m, "1 Hr", true)}
                                <Grid style={{ height: "0.2rem", backgroundColor: "#fff" }}></Grid>
                                <FREGraphForCandleStickChart token={selectedItem?.instrument_token} min="60" companySymbol={selectedItem?.symbol} tf="monthlyData" ht="348" />
                            </Grid>
                        </Box>
                    } */}
                    {
                        viewFRE === "gaan" &&
                        <Box className='w-full flex gap-1'>
                            <Grid style={{ width: "55%", border: '1px solid lightgray', borderRadius: '5px' }}>
                                {Object.values(speedoMeterData_d)?.length && graphHead(speedoMeterData_d, "15 Mins")}

                                <CombinedGraphIndicesandFutures token={selectedItem?.instrument_token} min="15" companySymbol={selectedItem?.symbol} tf="dailyData" ht="348" />
                            </Grid>
                            <Grid style={{ width: "23%", border: '1px solid lightgray', borderRadius: '5px' }}>
                                {Object.values(speedoMeterData_w)?.length && graphHead_wm(speedoMeterData_w, "30 Mins", true)}
                                <Grid style={{ height: "0.2rem", backgroundColor: "#fff" }}></Grid>
                                <CombinedGraphIndicesandFutures token={selectedItem?.instrument_token} min="30" companySymbol={selectedItem?.symbol} tf="weeklyData" ht="352" />
                            </Grid>
                            <Grid style={{ width: "20%", borderLeft: '1px solid lightgray', borderRadius: '5px' }}>
                                {Object.values(speedoMeterData_m)?.length && graphHead_wm(speedoMeterData_m, "1 Hr", true)}
                                <Grid style={{ height: "0.2rem", backgroundColor: "#fff" }}></Grid>
                                <CombinedGraphIndicesandFutures token={selectedItem?.instrument_token} min="60" companySymbol={selectedItem?.symbol} tf="monthlyData" ht="350" />
                            </Grid>
                        </Box>
                    }
                </>
            }
            {futures === "idf" && <>
                <Grid className="investers-trading-tabs" style={{ display: futures === "idf" ? "flex" : 'none', backgroundColor: "transparent" }}>
                    <TabContext value={viewFRE}>
                        <TabList className="tabs-grid"
                            onChange={handleChangeView}
                            aria-label="lab API tabs example">
                            {viewTabs.map((e, i) => (
                                <Tab key={i} className="tab-button" label={e.label} value={e.value} />
                            ))}
                        </TabList>
                    </TabContext>
                    {/* {view === "3" && <Grid style={{ margin: '2px 10px 2px 0px', display: 'flex', justifyContent: 'end' }}>
                        <FormGroup className="switch-view" >
                            <FormControlLabel control={<Switch checked={viewFRE} size="small" onClick={onViewClick} />} label="FRE Graph" />
                        </FormGroup>
                    </Grid>} */}
                    {/* {
                        view === "3" && <Grid style={{ margin: '0px 0px 2px 330px', display: 'flex', justifyContent: 'end' }}>
                            <FormGroup className="radio-view">
                                <RadioGroup row value={view} >
                                    <FormControlLabel
                                        value="fre"
                                        control={<Radio checked={viewFRE == 'fre'} size="small" />}
                                        label="FRE "
                                        onChange={onViewClick}
                                    />
                                    <FormControlLabel
                                        value="indice"
                                        control={<Radio checked={viewFRE == 'indice'} size="small" />}
                                        label="PP"
                                        onChange={onViewClick}
                                        className=''
                                    />
                                    <FormControlLabel
                                        value="gaan"
                                        control={<Radio checked={viewFRE == 'gaan'} size="small" />}
                                        label="GANN "
                                        onChange={onViewClick}
                                    />
                                </RadioGroup>
                            </FormGroup>
                        </Grid>
                    } */}
                </Grid>
            </>}
        </Box>
    </>
    )
}

export default withAuth(FutureAndOptions)