import dayjs from 'dayjs';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';
import { companyApiData, companyFibonacciData, companyGannOptions } from '../apiService/tradaApiService';
import { candleGraphApi, fetchThreeRangeBand } from '../apiService/tickerApiService';

const hregex = /H\d+/;
const lregex = /L\d+/;

const CombinedGraph = (props) => {
    const { token, min, companySymbol, yAxisValues, keys, tf, ht } = props;
    const [darkMode, setDarkMode] = useState(false);
    const [seriesData, setSeriesData] = useState([]);
    const [tfData, setTFData] = useState({});
    const [rangeBand, setRangeBand] = React.useState({})
    const [keysForGraph, setKeysForGraph] = useState({});
    const containerRef = useRef(null);
    const annotationsRef = useRef([]);
    const [chartHeight, setChartHeight] = useState(ht);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1300) {
                setChartHeight(ht * 2); // 30% of the prop ht
            } else if (window.innerWidth < 1299) {
                setChartHeight(ht * 1); // 50% of the prop ht
            } else if (window.innerWidth < 1200) {
                setChartHeight(ht * 2); // 70% of the prop ht
            } else {
                setChartHeight(ht); // Full ht from props
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial height based on screen width

        return () => window.removeEventListener('resize', handleResize);
    }, [ht]);
    const selectedKeys = ['key1', 'key2', 'key3', 'key4', 'key5'];
    const fetchData = async (t, s, m, tf) => {
        try {
            // Fetch pivot point data from multiple APIs
            // const [pivotPointsPPData, pivotPointsFBData, pivotPointsGOData] = await Promise.all([
            //     companyApiData(s),
            //     companyFibonacciData(s),
            //     companyGannOptions(s),
            // ]);
            // const sph1Value = getValuesByPattern(pivotPointsPPData?.data.dailyData, "SPH1");
            // const fph2Value = getValuesByPattern(pivotPointsPPData?.data.dailyData, "FPH2");
            // const spl1Value = getValuesByPattern(pivotPointsPPData?.data.dailyData, "SPL1");
            // const fpl2Value = getValuesByPattern(pivotPointsPPData?.data.dailyData, "FPL2");

            // const selectedKeys = [
            //     {
            //         value: Math.max(sph1Value, fph2Value),
            //         key: sph1Value >= fph2Value ? "SPH1" : "FPH2"  // Use SPH1 or FPH2 based on max value
            //     },
            //     {
            //         value: Math.min(sph1Value, fph2Value),
            //         key: sph1Value <= fph2Value ? "SPH1" : "FPH2"  // Use SPH1 or FPH2 based on min value
            //     },
            //     {
            //         value: Math.max(spl1Value, fpl2Value),
            //         key: spl1Value >= fpl2Value ? "SPL1" : "FPL2"  // Use SPL1 or FPL2 based on max value
            //     },
            //     {
            //         value: Math.min(spl1Value, fpl2Value),
            //         key: spl1Value <= fpl2Value ? "SPL1" : "FPL2"  // Use SPL1 or FPL2 based on min value
            //     }
            // ];
            // let mergedData;
            // if (min === '15') {
            //     mergedData = {
            //         dailyData: {
            //             ...Object.fromEntries(selectedKeys.map(({ value, key }) => [key, value])),
            //             // ...pivotPointsPPData?.data.dailyData,
            //             ...pivotPointsFBData?.data.dailyData,
            //             ...pivotPointsGOData?.data.dailyData,
            //             // ...rangeBand
            //         },
            //     };
            // } else if (min === '30') {
            //     mergedData = {
            //         weeklyData: {
            //             ...pivotPointsPPData?.data.weeklyData,
            //             ...pivotPointsFBData?.data.weeklyData,
            //             // ...pivotPointsGOData?.data.dailyData,
            //         },
            //     };
            // } else if (min === '60') {
            //     mergedData = {
            //         monthlyData: {
            //             ...pivotPointsPPData?.data.monthlyData,
            //             ...pivotPointsFBData?.data.monthlyData,
            //             // ...pivotPointsGOData?.data.dailyData,
            //         },
            //     };
            // } else {
            //     mergedData = {}; // Handle default case if needed
            // }

            // Fetch candlestick data for graph
            const currentDate = dayjs().format('YYYY-MM-DD');
            const candlesDataForGraph = await candleGraphApi(t, currentDate, currentDate, m);

            if (Array.isArray(candlesDataForGraph?.data)) {
                const formattedCandleData = candlesDataForGraph.data.map(e => ({
                    x: new Date(e?.x).getTime(),
                    y: e?.y,
                }));
                setSeriesData([{ data: formattedCandleData }]);
            } else {
                console.error('candlesDataForGraph.data is not an array', candlesDataForGraph);
                setSeriesData([{ data: [] }]);
            }

            // Set merged pivot point data and keys for the graph based on selected time frame
            // setTFData(mergedData || {});
            // Ensure tf (time frame) exists in mergedData
            // if (mergedData[tf]) {
            //     setKeysForGraph(mergedData[tf]);
            // } else {
            //     console.warn(`No data found for time frame: ${tf}`);
            //     setKeysForGraph([]);
            // }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const RangeBand = async (token) => {
        const currentDate = dayjs().format('YYYY-MM-DD');
        const oneDaysBefore = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        const tenDaysBefore = dayjs().subtract(10, 'day').format('YYYY-MM-DD');
        const _RangeBound = await fetchThreeRangeBand(tenDaysBefore, oneDaysBefore, token);
        setRangeBand(_RangeBound?.data)
    }

    const fetchPivotPoints = async (s) => {
        const [pivotPointsPPData, pivotPointsFBData, pivotPointsGOData] = await Promise.all([
            companyApiData(s),
            companyFibonacciData(s),
            companyGannOptions(s),
        ]);
        const sph1Value = getValuesByPattern(pivotPointsPPData?.data.dailyData, "SPH1");
        const fph2Value = getValuesByPattern(pivotPointsPPData?.data.dailyData, "FPH2");
        const spl1Value = getValuesByPattern(pivotPointsPPData?.data.dailyData, "SPL1");
        const fpl2Value = getValuesByPattern(pivotPointsPPData?.data.dailyData, "FPL2");

        const selectedKeys = [
            {
                value: Math.max(sph1Value, fph2Value),
                key: sph1Value >= fph2Value ? "SPH1" : "FPH2"  // Use SPH1 or FPH2 based on max value
            },
            {
                value: Math.min(sph1Value, fph2Value),
                key: sph1Value <= fph2Value ? "SPH1" : "FPH2"  // Use SPH1 or FPH2 based on min value
            },
            {
                value: Math.max(spl1Value, fpl2Value),
                key: spl1Value >= fpl2Value ? "SPL1" : "FPL2"  // Use SPL1 or FPL2 based on max value
            },
            {
                value: Math.min(spl1Value, fpl2Value),
                key: spl1Value <= fpl2Value ? "SPL1" : "FPL2"  // Use SPL1 or FPL2 based on min value
            }
        ];
        let mergedData;
        if (min === '15') {
            const oneDaysBefore = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
            const tenDaysBefore = dayjs().subtract(10, 'day').format('YYYY-MM-DD');
            const _RangeBound = await fetchThreeRangeBand(tenDaysBefore, oneDaysBefore, token);
            mergedData = {
                dailyData: {
                    ...Object.fromEntries(selectedKeys.map(({ value, key }) => [key, value])),
                    ...pivotPointsFBData?.data.dailyData,
                    ...pivotPointsGOData?.data.dailyData,
                    ..._RangeBound?.data
                },
            };
        } else if (min === '30') {
            mergedData = {
                weeklyData: {
                    ...pivotPointsPPData?.data.weeklyData,
                    ...pivotPointsFBData?.data.weeklyData,
                    ...pivotPointsGOData?.data.dailyData,
                },
            };
        } else if (min === '60') {
            mergedData = {
                monthlyData: {
                    ...pivotPointsPPData?.data.monthlyData,
                    ...pivotPointsFBData?.data.monthlyData,
                    ...pivotPointsGOData?.data.dailyData,
                },
            };
        } else {
            mergedData = {}; // Handle default case if needed
        }

        setTFData(mergedData || {});
        if (mergedData[tf]) {
            setKeysForGraph(mergedData[tf]);
        } else {
            console.warn(`No data found for time frame: ${tf}`);
            setKeysForGraph([]);
        }
    }
    useEffect(() => {
        let intervalId;

        intervalId = setInterval(() => {
            if (token && min && companySymbol) {
                fetchData(token, companySymbol, min)
            };
        }, 5000);
        return () => {
            clearInterval(intervalId)
        }

    }, [token, companySymbol]);

    useMemo(() => {
        if (token && min && companySymbol) {
            fetchPivotPoints(companySymbol)
            // RangeBand(token)
            fetchData(token, companySymbol, min)
        };
    }, [token, companySymbol])

    const getValuesByPattern = (data, pattern) => {
        if (!data) return {};
        return Object.keys(data).filter(key => key.startsWith(pattern)).reduce((acc, key) => {
            acc[key] = data[key];
            return data[key];
        }, {});
    };


    const getBorderWidth = (d, v) => {

        if (d['Buy_Entry'] == v || d['SPH1'] == v || d['SPL1'] == v || d["ATRLB"] == v || d["ATRMB"] == v || d["ATRUB"] == v) {
            return "4px";
        } else if (d['Sell_Entry'] == v || d['FPH2'] == v || d['FPL2'] == v) {
            return "4px";
        } else if (d['0'] == v || d["38"] == v || d["61"] == v || d['100'] == v) {
            return "4px";
        }
        let r2 = Math.max(getValuesByPattern(d, "SPH1"), getValuesByPattern(d, "FPH2"));
        let r1 = Math.min(getValuesByPattern(d, "SPH1"), getValuesByPattern(d, "FPH2"));
        let s1 = Math.max(getValuesByPattern(d, "SPL1"), getValuesByPattern(d, "FPL2"));
        let s2 = Math.min(getValuesByPattern(d, "SPL1"), getValuesByPattern(d, "FPL2"));

        if (r2 === v || r1 === v || s1 === v || s2 === v) return "4px";
        return "2px";
    };

    const getAlignment = (v) => {
        const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 250;
        switch (v) {
            case "15": return (0.036 * containerWidth);
            case "30": return 0.075 * containerWidth;
            case "60": return 0.13 * containerWidth;
        }

    }
    const fibonacciFontColor = (index, keys, _tf, v, d) => {
        let color;
        let i = keys[index];

        // Check for Buy/Sell Entries
        if (i === "Buy_Entry" || i.startsWith("B")) {
            color = "#008000"; // Green color for buy
        } else if (i === "Sell_Entry" || i.startsWith("S")) {
            color = "#FF0000"; // Red color for sell
        }
        // Check for Fibonacci values
        else if (i === "50") {
            color = "#444444"; // Gold color
        } else if (["0", "23", "38", "neg23", "neg38", "neg50", "neg61", "neg78", "neg100", "neg123", "neg138", "neg150"].includes(i)) {
            color = "#FF0000"; // Red color for values below 50
        } else if (["61", "78", "100", "123", "138", "150", "161", "178", "200", "223", "238", "250"].includes(i)) {
            color = "#008000"; // Green color for values above 50
        }
        // Default color
        else {
            color = "#FFFFFF"; // White color
        }
        if (i === "ATRLB") {
            color = "#FF0000";
        } else if (i === "ATRUB") {
            color = "#008000"; // Green color 
        } else if (i === "ATRMB") {
            color = "#444444";
        }

        if (hregex.test(i)) {
            color = "#339249"; // Custom color for high regex match
        } else if (lregex.test(i)) {
            color = "#DA2424"; // Custom color for low regex match
        }
        const r2 = Math.max(getValuesByPattern(d, "SPH1"), getValuesByPattern(d, "FPH2"));
        const r1 = Math.min(getValuesByPattern(d, "SPH1"), getValuesByPattern(d, "FPH2"));
        const s1 = Math.max(getValuesByPattern(d, "SPL1"), getValuesByPattern(d, "FPL2"));
        const s2 = Math.min(getValuesByPattern(d, "SPL1"), getValuesByPattern(d, "FPL2"));

        // Check if the value matches the calculated thresholds
        if (r2 === v || r1 === v) {
            return "#4d8fcc";
        }
        if (s2 === v || s1 === v) {
            return "#FFA3B3";
        }

        return color;
    };


    const getFREPrec = (v) => {

        switch (v) {
            case "ATRMB": return "(MB)"
            case "ATRUB": return "(UB)"
            case "ATRLB": return "(LB)"
            case "0": return "(0%)"
            case "23": return "(23.6%)"
            case "38": return "(38.2%)"
            case "50": return "(50%)"
            case "61": return "(61.8%)"
            case "78": return "(78.6%)"
            case "100": return "(100%)"
            case "123": return "(123.6%)"
            case "138": return "(138.2%)"
            case "150": return "(150%)"
            case "161": return "(161.8%)"
            case "178": return "(178.6%)"
            case "200": return "(200%)"
            case "223": return "(223.6%)"
            case "238": return "(238.2%)"
            case "250": return "(250%)"
            case "neg23": return "(-23.6%)"
            case "neg38": return "(-38.2%)"
            case "neg50": return "(-50%)"
            case "neg61": return "(-61.8%)"
            case "neg78": return "(-78.6%)"
            case "neg100": return "(-100%)"
            case "neg123": return "(-123.6%)"
            case "neg138": return "(-138.2%)"
            case "Buy_Entry": return "(45°)"
            case "B1":
                return "(90°)";
            case "B2":
                return "(135°)";
            case "B3":
                return "(180°)";
            case "B4":
                return "(225°)";
            case "B5":
                return "(270°)";
            case "B6":
                return "(315°)";
            case "B7":
                return "(360°)"; // Or "(0°)" depending on your preference
            case "B8":
                return "(405°)";
            case "B9":
                return "(450°)";
            case "B10":
                return "(495°)";
            case "B11":
                return "(540°)";
            case "B12":
                return "(585°)";
            case "B13":
                return "(630°)";
            case "B14":
                return "(675°)";
            case "B15":
                return "(720°)";
            case "B16":
                return "(765°)";
            case "B17":
                return "(810°)";
            case "B18":
                return "(855°)";
            case "B19":
                return "(900°)";
            case "B20":
                return "(945°)";
            case "Sell_Entry":
                return "(-45°)";
            case "S1":
                return "(-90°)";
            case "S2":
                return "(-135°)";
            case "S3":
                return "(-180°)";
            case "S4":
                return "(-225°)";
            case "S5":
                return "(-270°)";
            case "S6":
                return "(-315°)";
            case "S7":
                return "(-360°)"; // Or "(-0°)" depending on your preference
            case "S8":
                return "(-405°)";
            case "S9":
                return "(-450°)";
            case "S10":
                return "(-495°)";
            case "S11":
                return "(-540°)";
            case "S12":
                return "(-585°)";
            case "S13":
                return "(-630°)";
            case "S14":
                return "(-675°)";
            case "S15":
                return "(-720°)";
            case "S16":
                return "(-765°)";
            case "S17":
                return "(-810°)";
            case "S18":
                return "(-855°)";
            case "S19":
                return "(-900°)";
            case "S20":
                return "(-945°)";
            default: return ""
        }
    }
    const getYaxisArray = (a) => {
        const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 250;
        const percentageOffsetX = -0.05 * containerWidth;
        const results = Object.values(a).map((value, i) => ({ value, key: Object.keys(a)[i] }));
        return results.map(({ value, key }, i) => ({
            y: value,
            borderWidth: getBorderWidth(a, value, tf), // Choose based on condition
            borderColor: fibonacciFontColor(i, Object.keys(a), tf, value, a), // Dynamic color based on condition
            strokeDashArray: 0,
            label: {
                borderColor: '#00E396',
                borderWidth: '0px',
                style: {
                    display: 'block',
                    fontSize: '10px',
                    color: fibonacciFontColor(i, Object.keys(a), tf, value, a), // Dynamic label color
                    background: 'transparent',
                    fontWeight: "bold"
                },
                orientation: 'horizontal',
                offsetX: getAlignment(min),
                offsetY: 0,
                text: `${Number(value)?.toFixed(1) || '0'} ${getFREPrec(Object.keys(a)?.[i])}`,
            },
        })) || [];
    };


    const options = useMemo(() => ({
        chart: {
            type: 'candlestick',
            height: 390,
            background: darkMode ? '#1e1e1e' : '#fff',
            toolbar: {
                show: false,
                tools: { zoom: false, zoomin: false, zoomout: false, pan: true, reset: false },
            },
            zoom: {
                enabled: false,
                type: 'x',
                autoScaleYaxis: true
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: darkMode ? '#000' : '#000',
                    fontSize: '12px'
                },
                formatter: (val) => dayjs(val).format('HH:mm')
            },
            axisBorder: { color: darkMode ? '#777' : '#ddd' },
            axisTicks: { color: darkMode ? '#777' : '#ddd' }
        },
        yaxis: {
            labels: { show: true, style: { colors: darkMode ? '#fff' : '#fff' } },
            axisBorder: { show: false, color: darkMode ? '#777' : '#ddd' },
            axisTicks: { show: false, color: darkMode ? '#777' : '#ddd' },
            tooltip: { enabled: true },
        },
        grid: {
            show: true,
            borderColor: '#fff',
            yaxis: {
                lines: {
                    show: true,
                    color: '#fff',
                    width: 1,
                    dashArray: 4
                }
            },
            padding: { left: -60, right: 10 },
        },
        annotations: {
            yaxis: Object.keys(tfData).length > 0 ? getYaxisArray(tfData[tf]) : []
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            followCursor: true,
            theme: darkMode ? 'dark' : 'light',
            x: {
                show: true,
                formatter: (val) => dayjs(val).format('DD MMM YYYY, HH:mm'),
            },
            y: {
                show: true,
                formatter: (val) => val.toFixed(2),
                title: { formatter: () => 'Price: ' },
            },
            style: { fontSize: '12px' },
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#4CAF50',
                    downward: '#F44336',
                },
                wick: {
                    useFillColor: true,
                },
            },
        },
    }), [darkMode, tfData]);

    const series = useMemo(() => {
        return seriesData.length > 0 ? seriesData : [{ data: [] }];
    }, [seriesData]);

    return (
        <div className='chart-container' ref={containerRef}>
            {series.length > 0 ? (
                <Chart
                    options={options}
                    series={series}
                    type="candlestick"
                    // height={ht ? ht : 390}
                    height={chartHeight}
                />
            ) : (
                <p>Loading data...</p>
            )}
        </div>
    );
};

export default CombinedGraph;
