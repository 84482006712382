import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import SimpleCandle from '../common/SimpleCandle';
import SingleCandleStick from '../common/SingleCandleStick2';
import MetricCandleStick from '../common/MetricCandleStick';

function PosstiveContributors(props) {
  const { positive, negative, totalArrLength } = props;

  const calculateTotalContribution = (positive) => {
    return positive.reduce((total, item) => total + item.contribution, 0);
  };
  const totalContribution = calculateTotalContribution(positive);

  const getCmp = (obj) => (
    <Grid className=''>
      <Typography className="" style={{ whiteSpace: "nowrap" }}>
        {obj?.companyData?.companySymbol}
        <span style={{ fontSize: '11px', color: '#595e62', marginLeft: '5px' }}>
          {`(${obj?.changePercentage?.toFixed(2)}%)`}
        </span>
      </Typography>
      <Typography className="positive" style={{ whiteSpace: "nowrap" }}>
        {obj?.currentprice}
        <span style={{ fontSize: '11px', color: '#595e62', marginLeft: '5px' }}>
          {`(${obj?.difference?.toFixed(2)})`}
        </span>
      </Typography>
    </Grid >
  )
  return (
    <>
      <Box className='contributes-Top'>
        <Box className='flex' style={{ justifyContent: 'space-between', width: "100%" }}>
          <Typography className="mr-2 pos-cont">{totalContribution?.toFixed(2)}</Typography>
          <Typography className="positive" style={{ fontSize: '16px' }}>PositiveContributors
            <span style={{ fontSize: '11px', marginLeft: '5px', fontFamily: 'Futura PT Book' }}>({positive.length}/{totalArrLength})
            </span>
          </Typography>
        </Box>
        {/* <Typography className="positive" align='right' style={{width:"85%"}}>Positive Contributors</Typography> */}
      </Box>
      <TableContainer style={{ borderRadius: "10px", height: `calc(100vh - 235px)` }} className="custom-scrollbar mr-4">
        <Table stickyHeader aria-label="sticky table">
          <TableHead className='table-head'>
            <TableRow>
             
            <TableCell align='center'>Contribution</TableCell>
              <TableCell align='center'>Counter Name</TableCell>
              <TableCell align='center'>Wt.%</TableCell>
              {/* <TableCell align='center'>OHLC</TableCell> */}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {positive?.map((item, i) => {
            return (<TableBody key={i}>
              <TableRow className='table-row'>
                  <TableCell align='center' style={{ background: `linear-gradient(182deg, #2f9816, transparent)`, color: '#000', width: '80px', fontSize: '12px' }}>+ {item.contribution.toFixed(2)}</TableCell>
                  <TableCell align='center' style={{ width: '50px', fontSize: '12px',minWidth: "150px"}}>{getCmp(item)}</TableCell>
                  <TableCell align='center' style={{ fontSize: '12px',padding : "3px 0px" }}>{item.weightage}%</TableCell>
                <TableCell align='center' style={{ fontSize: '12px', minWidth: "150px", padding: '0px 10px' }}>
                  {/* <SingleCandleStick open={item?.current_open ? item?.current_open : item?.ticker_open} high={item?.current_high <= item?.ticker_high ? item?.ticker_high : item?.current_high} low={item?.current_low >= item?.ticker_low ? item?.ticker_low : item?.current_low == 0 ? item?.ticker_low : item?.current_low} close={item?.currentprice} obj={item} /> */}
                  <MetricCandleStick open={item?.current_open ? item?.current_open : item?.ticker_open} high={item?.current_high <= item?.ticker_high ? item?.ticker_high : item?.current_high} low={item?.current_low >= item?.ticker_low ? item?.ticker_low : item?.current_low == 0 ? item?.ticker_low : item?.current_low} close={item?.currentprice} percentage={item?.percentage} poscount={item?.poscount} negcount={item?.negcount} arrow={item?.arrow} totalMetrics={item?.totalMetrics} prev_range={item?.pre_percentage}
                  />
                </TableCell>
              </TableRow>
            </TableBody>)
          })}
        </Table>
      </TableContainer>
    </>
  )
}

export default PosstiveContributors