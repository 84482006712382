import React, { useEffect, useState } from 'react';
import { NiftyPlusPlus } from '../apiService/tickerApiService';
import { Grid, Typography } from '@material-ui/core';
import './Indexmovers.css';
import GaugeChartTF from '../common/GuageChartTF';
import { fetchFAndOData } from '../apiService/tradaApiService';
import MetricCandleStick from '../common/MetricCandleStick';

function FuturesPlusPlus() {
    const [nifty50Data, setNifty50Data] = useState({});
    const [bankNiftyData, setBankNiftyData] = useState({});
    const [finNiftyData, setFinNiftyData] = useState({});
    const [midCapData, setMidcapData] = useState({});
    const [finNextData, setFinNextData] = useState({});
    const [futures, setFutures] = useState([]);

    const fetchDataForNsePlusPlus = async () => {
        if (futures.length < 5) return; // Ensure there are enough futures

        const [nifty50_data, bankNifty_data, finNifty_data, midCap_data, finNext_data] = await Promise.all([
            NiftyPlusPlus(futures[0].symbol, futures[0].instrument_token),
            NiftyPlusPlus(futures[1].symbol, futures[1].instrument_token),
            NiftyPlusPlus(futures[2].symbol, futures[2].instrument_token),
            NiftyPlusPlus(futures[3].symbol, futures[3].instrument_token),
            NiftyPlusPlus(futures[4].symbol, futures[4].instrument_token)
        ]);

        setNifty50Data(nifty50_data?.data || {});
        setBankNiftyData(bankNifty_data?.data || {});
        setFinNiftyData(finNifty_data?.data || {});
        setMidcapData(midCap_data?.data || {});
        setFinNextData(finNext_data?.data || {});
    };

    const futuresSymbol = async () => {
        const _futures = await fetchFAndOData('idf');
        setFutures(_futures?.data?.data || []);
    };

    useEffect(() => {
        const fetchData = async () => {
            await futuresSymbol();
        };
        fetchData();
    }, []); // Empty dependency array to fetch data only once on mount

    useEffect(() => {
        if (futures.length > 0) {
            fetchDataForNsePlusPlus();
            const intervalId = setInterval(() => {
                fetchDataForNsePlusPlus();
            }, 4000);
            return () => clearInterval(intervalId);
        }
    }, [futures]); // Trigger only when futures change

    const getTF = (i) => {
        const timeframes = futures.map(f => f.symbol);
        return timeframes[i] || "N/A";
    };

    const getPerc = (cmp, pc) => {
        return pc ? (((cmp - pc) / pc) * 100).toFixed(2) : 0;
    };

    return (
        <Grid className='niftyplus flex w-full gap-1 bg-white'>
           {Object.values(finNextData)?.length > 0 ? [nifty50Data, bankNiftyData, finNiftyData, midCapData, finNextData].map((s, i) => {
                const traderViewPercentages = [s?.daily?.length > 0 ? s?.daily[0]?.percentage?.toFixed(2) : 0, s?.weekly.length > 0 ? s?.weekly[0]?.percentage.toFixed(2) : 0, s?.monthly?.length > 0 ? s?.monthly[0]?.percentage.toFixed(2) : 0]
                const traderOpen = [s?.daily[0]?.current_open ? s?.daily[0]?.current_open : s?.daily[0]?.ticker_open, s?.weekly[0]?.current_open ? s?.weekly[0]?.current_open : s?.weekly[0]?.ticker_open, s?.monthly[0]?.current_open ? s?.monthly[0]?.current_open : s?.monthly[0]?.ticker_open]

                const tradaHigh = [s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high, s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high, s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high]

                const traderLow = [s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low, s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low, s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low]

                const tradaClose = [s?.daily[0]?.current_close, s?.weekly[0]?.current_close, s?.monthly[0]?.current_close]

                const tradaRange = [s?.daily[0]?.pre_percentage, s?.weekly[0]?.pre_percentage, s?.monthly[0]?.pre_percentage]

                const previousclose = [s?.daily[0]?.pre_close, s?.weekly[0]?.pre_close, s?.monthly[0]?.pre_close]


                return (
                    <>
                        <Grid className='w-2/6 flex flex-col'>
                            <Grid className='w-full border-timeframe plusplus-border p-0'>
                                <Grid className='header-timeframe'>
                                    <Typography align='center' className='font-semibold font-futura' style={{ fontSize: '14px',padding:'2px ' }}>{getTF(i, true)} 
                                        {/* ( {s?.daily[0]?.currentprice} ) */}
                                        <span style={{ color: s?.daily[0]?.pre_close > s?.daily[0]?.currentprice ? 'red' : 'green', background: '#fff', padding: '0px 3px', borderRadius: '5px', marginLeft: '2px' }}> {s?.daily[0]?.currentprice} </span>

                                    </Typography>
                                </Grid>
                                <Grid className=''>
                                    <GaugeChartTF tf={["Daily", "Weekly", "Monthly"]} previousclose={previousclose} tradaRange={tradaRange} tradaClose={tradaClose} tradaHigh={tradaHigh} traderOpen={traderOpen} traderLow={traderLow} viewPercentages={traderViewPercentages} mean={((Number(traderViewPercentages[0]) + Number(traderViewPercentages[1]) + Number(traderViewPercentages[2])) / 3)?.toFixed(2)} cmp={s?.daily[0]?.currentprice} />
                                    <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                        <Grid className="flex justify-center -mb-1" >
                                            <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(D) - {getTF(i)}
                                                {/* <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.daily[0]?.currentprice}</span> */}
                                                <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.daily[0]?.currentprice - s?.daily[0]?.pre_close).toFixed(2)})({getPerc(s?.daily[0]?.currentprice, s?.daily[0]?.pre_close)}%)</span>
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ marginTop: '4px' }}>
                                            <MetricCandleStick open={s?.daily[0]?.current_open ? s?.daily[0]?.current_open : s?.daily[0]?.ticker_open}
                                                high={s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high}
                                                low={s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low}
                                                close={s?.daily[0]?.current_close} poscount={s?.daily[0]?.poscount} negcount={s?.daily[0]?.negcount} arrow={s?.daily[0]?.arrow} totalMetrics={s?.daily[0]?.totalMetrics} prev_range={s?.daily[0]?.pre_percentage}
                                                percentage={s?.daily?.[0]?.percentage} />
                                        </Grid>
                                        <Grid className='im-ohlc flex w-full'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high) - (s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.daily[0]?.pre_high - s?.daily[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.daily[0]?.pre_low) ? (s?.daily[0]?.pre_high - s?.daily[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.daily[0]?.current_open == 0 ? s?.daily[0]?.ticker_open : s?.daily[0]?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.daily[0]?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high?.toFixed(1) : s?.daily[0]?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low?.toFixed(1) : s?.daily[0]?.current_low == "0" ? s?.daily[0]?.ticker_low?.toFixed(1) : s?.daily[0]?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                        <Grid className="flex justify-center -mb-1">
                                            <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(W) - {getTF(i)}
                                                {/* <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.weekly[0]?.currentprice}</span> */}
                                                <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.weekly[0]?.currentprice - s?.weekly[0]?.pre_close).toFixed(1)})({getPerc(s?.weekly[0]?.currentprice, s?.weekly[0]?.pre_close)}%)</span>
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ marginTop: '4px' }}>
                                            <MetricCandleStick open={s?.weekly[0]?.current_open ? s?.weekly[0]?.current_open : s?.weekly[0]?.ticker_open}
                                                high={s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high}
                                                low={s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low}
                                                close={s?.weekly[0]?.current_close} poscount={s?.weekly[0]?.poscount} negcount={s?.weekly[0]?.negcount} arrow={s?.weekly[0]?.arrow} totalMetrics={s?.weekly[0]?.totalMetrics} prev_range={s?.weekly[0]?.pre_percentage}
                                                percentage={s?.weekly?.[0]?.percentage} />
                                        </Grid>
                                        <Grid className='im-ohlc flex w-full'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high) - (s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.weekly[0]?.pre_high - s?.weekly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.weekly[0]?.pre_low) ? (s?.weekly[0]?.pre_high - s?.weekly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.weekly[0]?.current_open == 0 ? s?.weekly[0]?.ticker_open : s?.weekly[0]?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.weekly[0]?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high?.toFixed(1) : s?.weekly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low?.toFixed(1) : s?.weekly[0]?.current_low == "0" ? s?.weekly[0]?.ticker_low?.toFixed(1) : s?.weekly[0]?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                        <Grid className="flex justify-center -mb-1">
                                            <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(M) - {getTF(i)}
                                                {/* <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.monthly[0]?.currentprice}</span> */}
                                                <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.monthly[0]?.currentprice - s?.monthly[0]?.pre_close).toFixed(1)})({getPerc(s?.monthly[0]?.currentprice, s?.monthly[0]?.pre_close)}%)</span>
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ marginTop: '4px' }}>
                                            <MetricCandleStick open={s?.monthly[0]?.current_open ? s?.monthly[0]?.current_open : s?.monthly[0]?.ticker_open}
                                                high={s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high}
                                                low={s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low}
                                                close={s?.monthly[0]?.current_close} poscount={s?.monthly[0]?.poscount} negcount={s?.monthly[0]?.negcount} arrow={s?.monthly[0]?.arrow} totalMetrics={s?.monthly[0]?.totalMetrics} prev_range={s?.monthly[0]?.pre_percentage}
                                                percentage={s?.monthly?.[0]?.percentage} />
                                        </Grid>
                                        <Grid className='im-ohlc flex w-full'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high) - (s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.monthly[0]?.pre_high - s?.monthly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.monthly[0]?.pre_low) ? (s?.monthly[0]?.pre_high - s?.monthly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.monthly[0]?.current_open == 0 ? s?.monthly[0]?.ticker_open : s?.monthly[0]?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.monthly[0]?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high?.toFixed(1) : s?.monthly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low?.toFixed(1) : s?.monthly[0]?.current_low == "0" ? s?.monthly[0]?.ticker_low?.toFixed(1) : s?.monthly[0]?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )
            }) : <Typography align='center' className='font-semibold font-futura'>Loading...</Typography>}
        </Grid>
    );
}

export default FuturesPlusPlus;
