import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getCmpNew } from '../apiService/tickerApiService'

function IndiceStripNew(props) {
    const { showNifty50, showBankNifty, showITNifty, showNIFTYf, showBankNiftyF } = props
    const [nifty50Data, setNifty50Data] = useState({})
    const [bankNiftyData, setBankNiftyData] = useState({})
    const [itNiftyData, setItNiftyData] = useState({})
    const [finNiftyData, setFinNiftyData] = useState({})
    const [next50NiftyData, setNext50Data] = useState({})
    const [midCapData, setMidCapData] = useState({})

    const [niftyFutureData, setNiftyFutureData] = useState({})
    const [bankFutureData, setBankFutureData] = useState({})
    const [finFutureData, setFinFutureData] = useState({})
    const [midCapFutureData, setMidCapFutureData] = useState({})
    const [next50FutureData, setNext50FutureData] = useState({})

    const getCmp = async () => {
        const fetchCmp = await getCmpNew()
        if (fetchCmp?.data?.length > 0) {
            setNifty50Data(fetchCmp?.data?.find(obj => obj.symbol === "NIFTY50"))
            setBankNiftyData(fetchCmp?.data?.find(obj => obj.symbol === "BANKNIFTY"))
            setItNiftyData(fetchCmp?.data?.find(obj => obj.symbol === "ITNIFTY"))
            setFinNiftyData(fetchCmp?.data?.find(obj => obj.symbol === "FINNIFTY"))
            setNext50Data(fetchCmp?.data?.find(obj => obj.symbol === "NIFTYNEXT50"))
            setMidCapData(fetchCmp?.data?.find(obj => obj.symbol === "MIDCAPSELECT"))

            setNiftyFutureData(fetchCmp?.data?.find(obj => obj.symbol.startsWith("NIFTY2") && obj.symbol.endsWith("FUT")))
            setBankFutureData(fetchCmp?.data?.find(obj => obj.symbol.startsWith("BANKNIFTY") && obj.symbol.endsWith("FUT")))
            setFinFutureData(fetchCmp?.data?.find(obj => obj.symbol.startsWith("FINNIFTY") && obj.symbol.endsWith("FUT")))
            setMidCapFutureData(fetchCmp?.data?.find(obj => obj.symbol.startsWith("MIDCPNIFTY") && obj.symbol.endsWith("FUT")))
            setNext50FutureData(fetchCmp?.data?.find(obj => obj.symbol.startsWith("NIFTYNXT50") && obj.symbol.endsWith("FUT")))
        }
    }

    useEffect(() => {
        let intervalId = setInterval(() => {
            getCmp()
        }, 1500);
        return () => { clearInterval(intervalId) }
    }, [])

    return (
        <>
            <Grid className='indices-cmp'>
                {showNifty50 && <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid className="details">
                        <Grid className="details-context" style={{ fontSize: '14px' }}>
                            <span>|</span>{nifty50Data?.symbol}
                        </Grid>
                    </Grid>
                    <Grid >
                        <Grid className="details-context">
                            <Grid className='pricing'>
                                <Grid style={{ color: `${nifty50Data?.prevClode <= nifty50Data?.currentprice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, display: "flex", alignItems: "baseline" }}>
                                    <p className='mr-1' style={{ fontSize: '12px' }}>{nifty50Data?.currentprice ? nifty50Data?.currentprice?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{nifty50Data?.currentprice ? (nifty50Data?.currentprice - nifty50Data?.prevClode)?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${nifty50Data?.changePercentage ? nifty50Data?.changePercentage?.toFixed(2) : 0}%)`}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

                {showNIFTYf && <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid className="details">
                        <Grid className="details-context" style={{ fontSize: '14px' }}>
                            {/* <span>|</span>{niftyFutureData?.symbol} */}
                            <span>|</span> NIFTY(F)
                        </Grid>
                    </Grid>
                    <Grid >
                        <Grid className="details-context">
                            <Grid className='pricing'>
                                <Grid style={{ color: `${niftyFutureData?.prevClode <= niftyFutureData?.currentprice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, display: "flex", alignItems: "baseline" }}>
                                    <p className='mr-1' style={{ fontSize: '12px' }}>{niftyFutureData?.currentprice ? niftyFutureData?.currentprice?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{niftyFutureData?.currentprice ? (niftyFutureData?.currentprice - niftyFutureData?.prevClode)?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${niftyFutureData?.changePercentage ? niftyFutureData?.changePercentage?.toFixed(2) : 0}%)`}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

                {showBankNifty && <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid className="details">
                        <Grid className="details-context" style={{ fontSize: '14px' }}>
                            <span>|</span>{bankNiftyData?.symbol}
                        </Grid>
                    </Grid>
                    <Grid >
                        <Grid className="details-context">
                            <Grid className='pricing'>
                                <Grid style={{ color: `${bankNiftyData?.prevClode <= bankNiftyData?.currentprice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, display: "flex", alignItems: "baseline" }}>
                                    <p className='mr-1' style={{ fontSize: '12px' }}>{bankNiftyData?.currentprice ? bankNiftyData?.currentprice?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{bankNiftyData?.currentprice ? (bankNiftyData?.currentprice - bankNiftyData?.prevClode)?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${bankNiftyData?.changePercentage ? bankNiftyData?.changePercentage?.toFixed(2) : 0}%)`}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

                {showBankNiftyF && <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid className="details">
                        <Grid className="details-context" style={{ fontSize: '14px' }}>
                            {/* <span>|</span>{bankFutureData?.symbol} */}
                            <span>|</span>BANK NIFTY(F)
                        </Grid>
                    </Grid>
                    <Grid >
                        <Grid className="details-context">
                            <Grid className='pricing'>
                                <Grid style={{ color: `${bankFutureData?.prevClode <= bankFutureData?.currentprice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, display: "flex", alignItems: "baseline" }}>
                                    <p className='mr-1' style={{ fontSize: '12px' }}>{bankFutureData?.currentprice ? bankFutureData?.currentprice?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{bankFutureData?.currentprice ? (bankFutureData?.currentprice - bankFutureData?.prevClode)?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${bankFutureData?.changePercentage ? bankFutureData?.changePercentage?.toFixed(2) : 0}%)`}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

                {showITNifty && <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid className="details">
                        <Grid className="details-context" style={{ fontSize: '14px' }}>
                            <span>|</span>{itNiftyData?.symbol}
                        </Grid>
                    </Grid>
                    <Grid >
                        <Grid className="details-context">
                            <Grid className='pricing'>
                                <Grid style={{ color: `${itNiftyData?.prevClode <= itNiftyData?.currentprice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, display: "flex", alignItems: "baseline" }}>
                                    <p className='mr-1' style={{ fontSize: '12px' }}>{itNiftyData?.currentprice ? itNiftyData?.currentprice?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{itNiftyData?.currentprice ? (itNiftyData?.currentprice - itNiftyData?.prevClode)?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${itNiftyData?.changePercentage ? itNiftyData?.changePercentage?.toFixed(2) : 0}%)`}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

            </Grid>
        </>
    )
}

export default IndiceStripNew