import React, { useEffect, useMemo, useState } from 'react'
import { NsePlusData } from '../apiService/tickerApiService'
import { Grid, Typography } from '@material-ui/core'
import MetricCandleStick from '../common/MetricCandleStick'
import GaugeChart from '../common/GaugeChart'
import './Indexmovers.css';
import { getColorPr, rangePerc } from '../../utils';
import { ArrowDownward, ArrowUpward, Height } from '@material-ui/icons'
import GaugeChartForDatatable from '../common/GaugeChartForDatatable'

function NSEPlus() {
    const [dailyData, setDailyData] = useState([])
    const [weeklyData, setWeeklyData] = useState([])
    const [monthlyData, setMonthlyData] = useState([])
    const [quarterlyData, setQuarterlyData] = useState([])
    const [halfYearlyData, setHalfYearlylyData] = useState([])
    const [yearlyData, setYearlyData] = useState([])

    const fetchDataForNsePlus = async () => {
        const d_data = await NsePlusData("D")
        const w_data = await NsePlusData("W")
        const m_data = await NsePlusData("M")
        const q_data = await NsePlusData("Q")
        const h_data = await NsePlusData("H")
        const y_data = await NsePlusData("Y")

        setDailyData(d_data?.data)
        setWeeklyData(w_data?.data)
        setMonthlyData(m_data?.data)
        setQuarterlyData(q_data?.data)
        setHalfYearlylyData(h_data?.data)
        setYearlyData(y_data?.data)
    }

    useEffect(() => {
        let intervalId = setInterval(() => {
            fetchDataForNsePlus()
        }, 4000)
        return () => clearInterval(intervalId)
    }, [dailyData])

    useMemo(() => {
        fetchDataForNsePlus()
    }, [])
    const getTF = (i) => {
        switch (i) {
            case 0: return "Daily"
            case 1: return "Weekly"
            case 2: return "Monthly"
            case 3: return "Quarterly"
            case 4: return "Half Yearly"
            case 5: return "Yearly"
        }
    }
    const getPerc = (cmp, pc) => {
        let a = ((cmp - pc) / pc) * 100
        return a?.toFixed(1)
    }

    const getColor = (v) => {  
        if (v <= 20) return "#da2424"
        else if (v > 20 && v <= 40) return "#f74f4f"
        else if (v > 40 && v <= 60) return "#ffb200e3"
        else if (v > 60 && v <= 80) return "#44db67"
        else if (v > 80) return "#339249"
        
    }

    return (
        <Grid className='niftyplus flex w-full h-full gap-1 -ml-2 bg-white'>
            {[dailyData, weeklyData, monthlyData, quarterlyData, halfYearlyData, yearlyData]?.map((indice, index) => {
                const nifty50Data = indice?.find(e => e?.companyData?.companySymbol === "NIFTY50")
                const bnniftyData = indice?.find(e => e?.companyData?.companySymbol === "BANKNIFTY")
                const itniftyData = indice?.find(e => e?.companyData?.companySymbol === "ITNIFTY")
                // const nxt50Data = indice?.find(e => e?.companyData?.companySymbol === "NEXT50")RELIANCE
                const RELIANCEData = indice?.find(e => e?.companyData?.companySymbol === "RELIANCE")
                const midCapData = indice?.find(e => e?.companyData?.companySymbol === "MIDCAPSELECT")
                const finNiftyData = indice?.find(e => e?.companyData?.companySymbol === "FINNIFTY")
                let downCount = indice.filter(item => item.arrow === "DOWN").length;
                let normalCount = indice.filter(item => item.arrow === "NORMAL").length;
                let upCount = indice.filter(item => item.arrow === "UP").length;
                let Count = indice?.length;
                let maxCount = Math.max(downCount, normalCount, upCount);
                return (
                    <Grid className='w-1/6 border-timeframe indice-border p-0'>
                        <Grid className='header-timeframe'>
                            <Typography align='center' className='font-black' style={{ fontSize: '14px' }}>{getTF(index)}</Typography>
                            {/* <Typography variant='body2' className='font-black mb-2 mt-1' align='center'>
                                <span style={{ border: '1px solid #fff', borderRadius: '5px', padding: '2px 5px', background: `${maxCount === upCount ? '#339249' : ''}`, fontSize: `${maxCount === upCount ? '14px' : '10px'}` }}><ArrowUpward style={{ fontSize: "18px", marginTop: "-3px" }} />{upCount}/{Count}</span>
                                <span style={{ border: '1px solid #fff', borderRadius: '5px', margin: '0px 5px', padding: '2px 5px', background: `${maxCount === normalCount ? '#ffa100e3' : ''}`, fontSize: `${maxCount === normalCount ? '14px' : '10px'}` }}><Height style={{ rotate: "90deg", marginTop: "-3px" }} />{normalCount}/{Count}</span>
                                <span style={{ border: '1px solid #fff', borderRadius: '5px', padding: '2px 5px', background: `${maxCount == downCount ? '#da2424' : ''}`, fontSize: `${maxCount === downCount ? '14px' : '10px'}` }}><ArrowDownward style={{ fontSize: "18px", marginTop: "-3px" }} />{downCount}/{Count}</span>
                            </Typography> */}
                        </Grid>
                        {[nifty50Data, bnniftyData, itniftyData, finNiftyData, midCapData, RELIANCEData]?.map((s, i) => {
                            // 
                            return (
                                <Grid className='nse-border'>
                                    <GaugeChart  value={s?.percentage?.toFixed(2)} previousclose={s?.pre_close}
                                    curr_range={s?.current_percentage} 
                                    // current_percentage
                                     cmp={s?.currentprice} 
                                      open={s?.current_open ? s?.current_open : s?.ticker_open}
                                       high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} 
                                       low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low} close={s?.current_close} prev_range={Number.isFinite(s?.pre_percentage) ? s?.pre_percentage : 0}/>
                                    {/* <GaugeChart value={s?.percentage?.toFixed(2)} open={s?.current_open ? s?.current_open : s?.ticker_open} high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low}
                                        close={s?.current_close} prev_range={Number.isFinite(s?.pre_percentage) ? s?.pre_percentage : 0} cmp={s?.currentprice}/> */}
                                    <Grid className="flex justify-center">
                                        <Typography variant='body2' className="font-black flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `${s?.currentprice > s?.pre_close ? "#000066" : "#DA2424"}` }}>{s?.companyData?.companySymbol}
                                            <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.currentprice}</span>
                                            <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.currentprice - s?.pre_close).toFixed(1)})({getPerc(s?.currentprice, s?.pre_close)}%)</span>
                                        </Typography>
                                    </Grid>
                                    <Grid className='im-ohlc flex w-full'>
                                        <Grid className="im-lc w-1/3">
                                            <Typography align="center" className="high-color">C.R: {rangePerc(s?.currentprice,s?.current_high,s?.current_low)}%</Typography>
                                            <Typography align="center" className="" style={{color :getColorPr(s?.pre_percentage)}}>P.R: {s?.pre_percentage ? s?.pre_percentage?.toFixed(2) : 0}%</Typography>
                                        </Grid>
                                        <Grid className="im-oh w-1/3">
                                            <Typography align="center" className="high-color">O: {s?.current_open == 0 ? s?.ticker_open : s?.current_open?.toFixed(1)}</Typography>
                                            <Typography align="center" className="high-color">P.C: {s?.pre_close?.toFixed(1)}</Typography>
                                        </Grid>
                                        <Grid className="im-rp w-1/3">
                                            <Typography align="center" className="high-color">H: {s?.current_high >= s?.ticker_high ? s?.current_high?.toFixed(1) : s?.ticker_high?.toFixed(1)}</Typography>
                                            <Typography align="center" className="low-color">L: {s?.current_low >= s?.ticker_low ? s?.ticker_low?.toFixed(1) : s?.current_low == "0" ? s?.ticker_low?.toFixed(1) : s?.current_low?.toFixed(1)} </Typography>
                                        </Grid>
                                    </Grid>
                                    <MetricCandleStick
                                        percentage={s?.percentage}
                                        open={s?.current_open ? s?.current_open : s?.ticker_open}
                                        high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high}
                                        low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low}
                                        close={s?.current_close}
                                        poscount={s?.poscount}
                                        negcount={s?.negcount}
                                        arrow={s?.arrow}
                                        totalMetrics={s?.totalMetrics}
                                        prev_range={s?.pre_percentage}
                                    />
                                </Grid>)
                        })}
                    </Grid>)
            })}
        </Grid>
    )
}

export default NSEPlus